import { appTheme } from 'styles/themes/defaultTheme';

export interface DiscountComponentProps {
  disabled?: boolean;
}

export type CheckboxState = {
  checked: boolean;
};

export interface StyledLabelProps {
  disabled?: boolean;
  theme?: appTheme;
}

export const DISCOUNT_VALUE = 'image.metadata.discount.value';
