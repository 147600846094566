import React, { useEffect, useState } from 'react';
import { tabName } from 'components/header/Header.consts';
import { Actions, StyledHeader, StyledTotalAmountWithTable, TableContainer } from 'pages/shared/shared.style';
import { store } from 'app/store';
import { useSelector } from 'react-redux';
import { getCampaignById } from 'utils/api/campaigns';
import { ActionType } from 'components/shared/actionsCell/ActionsCell.consts';
import { hideActionsCell, showActionsCell } from 'components/shared/table/Table.util';
import { ApprovalStatus, DateTimeConfig, OrderDirection } from 'utils/types';
import { isInArray } from 'utils/array';
import { useUrlFilters } from 'hooks/use-url-filters';
import { marketConfig } from 'app/slices/config';
import { useMutation, useQuery } from '@apollo/client';
import isEqual from 'lodash/isEqual';
import useQueryInterval from 'hooks/use-query-polling';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { mapOrder } from 'utils/mapping';
import {
  CampaignFilterType,
  CampaignProps,
  CampaignsSelection,
} from 'pages/campaigns/campaignManagement/Campaigns.consts';
import {
  ActionButtonsContainer,
  StyledTable,
  StyledTotalAmountContainer,
  TableHeader,
} from 'pages/campaigns/campaignManagement/Campaigns.style';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { FetchPolicies } from 'utils/types/common';
import { useToastError } from 'hooks/use-toast-error';
import { doeCampaignsPage } from 'app/genericSlices/campaigns';
import CampaignsFilterBar from 'pages/campaigns/campaignManagement/components/campaignsFilterBar/CampaignsFilterBar';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';
import TextPageTitle from 'components/shared/text/textPageTitle/TextPageTitle';
import { Modals, openModal } from 'app/slices/modals';
import QuickFilters from '../campaignManagement/components/quickFilters/QuickFilters';
import { getCampaignsTableProps } from '../campaignManagement/CampaignTableProps';
import PageContainer from '../../shared/pageContainer/PageContainer';
import { RoleGuard } from '../../../components/roleGuard/RoleGuard';
import { UserRole } from '../../../utils/types/users';
import { CampaignBulkAction } from '../campaignManagement/components/campaignBulkActions/CampaignBulkActions.consts';
import CampaignBulkActions from '../campaignManagement/components/campaignBulkActions/CampaignBulkActions';
import { users } from 'app/slices/users';
import { CampaignAlert, ResponseObject } from 'utils/types/campaigns';
import { capitalizeArrayElements } from 'utils/text';
import { DoeGqls } from './Doe.gqls';

const DoeCampaigns = () => {
  const { order, filters } = useSelector(doeCampaignsPage.campaignsState);
  const [bulkAction, setBulkAction] = useState<CampaignBulkAction>(null);

  const forceStatusFilter = bulkAction
    ? bulkAction === CampaignBulkAction.BulkStopAssociation
      ? [ApprovalStatus.Active]
      : [ApprovalStatus.Active, ApprovalStatus.Deployed]
    : null;

  const { data, error, fetchMore, loading } = useQuery<{
    getDoeCampaigns: {
      items: CampaignProps[];
      total: number;
      totalLocal?: number;
      totalNonLocalConflicted?: number;
      totalNotEligibleForSelfApproval?: number;
      totalInvalid?: number;
    };
  }>(DoeGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: doeCampaignsPage.getFilters(
          {
            ...filters,
            [CampaignFilterType.Status]:
              forceStatusFilter && forceStatusFilter.length > 0 ? forceStatusFilter : filters.status,
          },
          null,
          bulkAction,
        ),
        offerSource: OfferSource.DOE,
        order,
        limit: 24,
        offset: 0,
        ...(bulkAction && { action: bulkAction }),
      },
    },
  });

  const [bulkCampaignsRevokeOrStopAssociation, bulkCampaignsRevokeOrStopAssociationState] = useMutation<ResponseObject>(
    DoeGqls.mutations.bulkCampaignsRevokeOrStopAssociation,
    {
      refetchQueries: ['DoeCampaigns', 'GetCampaignsQuickFilters'],
      awaitRefetchQueries: true,
    },
  );

  const {
    getDoeCampaigns: {
      items: doeCampaigns,
      total,
      totalLocal,
      totalNonLocalConflicted,
      totalNotEligibleForSelfApproval,
      totalInvalid,
    },
  } = data || { getDoeCampaigns: { items: [] as CampaignProps[] } };

  const { startPolling, stopPolling } = useQuery<{
    getDoeCampaigns: {
      items: CampaignProps[];
      total: number;
      totalLocal?: number;
      totalNonLocalConflicted?: number;
      totalNotEligibleForSelfApproval?: number;
      totalInvalid?: number;
    };
  }>(DoeGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: doeCampaignsPage.getFilters(
          {
            ...filters,
            [CampaignFilterType.Status]:
              forceStatusFilter && forceStatusFilter.length > 0 ? forceStatusFilter : filters.status,
          },
          null,
          bulkAction,
        ),
        offerSource: OfferSource.DOE,
        order,
        limit: doeCampaigns.length || 24,
        offset: 0,
        ...(bulkAction && { action: bulkAction }),
        bulkActionsCounters: Boolean(bulkAction),
      },
    },
  });

  useToastError(error, 'Error loading Doe Campaigns');
  useQueryInterval(60_000, startPolling, stopPolling);

  const { config } = useSelector(marketConfig);
  const { user } = useSelector(users);
  const { dateFormat, timeFormat, startTimezone, endTimezone, enableCampaignSelfApproval } = config;
  const dateTimeConfig: DateTimeConfig = { dateFormat, timeFormat, startTimezone, endTimezone };
  const [gridSortModel, setGridSortModel] = useState<GridSortModel>(mapOrder(order));
  const [doeCampaignsSelection, setDoeCampaignsSelection] = useState<CampaignsSelection>(null);
  const selectedCampaignsIds = new Set<number>(doeCampaignsSelection?.selectedCampaignsIds ?? []);
  const excludedCampaignsIds = new Set<number>(doeCampaignsSelection?.excludedCampaignsIds ?? []);
  const isEligibleForSelfApproval =
    enableCampaignSelfApproval && isInArray([UserRole.SysAdmin, UserRole.Admin], user.role);

  const urlFilters = useUrlFilters((params: any) => {
    store.dispatch(
      doeCampaignsPage.actions.setFilters(
        Object.keys(params).reduce(
          (res: any, key: any) => ({
            ...res,
            [key]:
              Array.isArray(params[key]) || isInArray(['true', 'false'], params[key]) ? params[key] : [params[key]],
          }),
          {},
        ),
      ),
    );
  });

  const onActionClick = async (actionType: ActionType, campaign: CampaignProps) => {
    if (isInArray([ActionType.Edit, ActionType.View], actionType)) {
      const campaignData = await getCampaignById(campaign.id);
      store.dispatch(
        openModal({
          modal: Modals.CampaignModal,
          props: { mode: actionType, campaign: campaignData?.data?.getCampaign },
        }),
      );
    }
  };

  const getDoeCampaignsDetails = doeCampaigns.map((items) => ({
    ...items,
    externalId: items.externalId ?? '  -  ',
  }));

  const fetchNextCampaigns = async () => {
    if (doeCampaigns?.length < total) {
      await fetchMore({
        variables: {
          data: {
            filters: doeCampaignsPage.getFilters(
              {
                ...filters,
                [CampaignFilterType.Status]:
                  forceStatusFilter && forceStatusFilter.length > 0 ? forceStatusFilter : filters.status,
              },
              null,
              bulkAction,
            ),
            offset: doeCampaigns.length,
            limit: 16,
            order,
            offerSource: OfferSource.DOE,
          },
        },
      });
    }
  };

  const onSortModelChange = (sortModel: { [key: string]: OrderDirection }, gridSortModal: GridSortModel) => {
    if (!isEqual(sortModel, order)) {
      store.dispatch(doeCampaignsPage.actions.setOrder(sortModel));
      setGridSortModel(gridSortModal);
    }
  };

  const onBulkActionChange = (action: CampaignBulkAction) => {
    setBulkAction(action);
    setDoeCampaignsSelection(
      action
        ? {
            virtualSelectAll: true,
            excludedCampaignsIds: [],
            selectedCampaignsIds: [],
          }
        : null,
    );
  };

  const onBulkActionSubmit = async (action: CampaignBulkAction): Promise<bigint[]> => {
    const bulkActionData = await bulkCampaignsRevokeOrStopAssociation({
      variables: {
        data: {
          doeCampaignsSelection,
          status: capitalizeArrayElements(
            bulkAction === CampaignBulkAction.BulkStopAssociation
              ? [ApprovalStatus.Active]
              : [ApprovalStatus.Active, ApprovalStatus.Deployed],
          ),

          filters: doeCampaignsPage.getFilters(
            {
              ...filters,
              [CampaignFilterType.Status]: forceStatusFilter,
            },
            null,
            bulkAction,
          ),
          total,
          action: action,
        },
      },
    });
    return bulkActionData.data.offerPropIds;
  };

  const getSelectAllState = () => ({
    checked:
      selectedCampaignsIds.size === total || (doeCampaignsSelection?.virtualSelectAll && !excludedCampaignsIds.size),
    intermediate:
      (selectedCampaignsIds.size > 0 && selectedCampaignsIds.size !== total) ||
      (doeCampaignsSelection?.virtualSelectAll && excludedCampaignsIds.size > 0),
  });

  const onSelectAll = () => {
    const selectAllState = getSelectAllState();
    const updatedVirtualSelectAll = !doeCampaignsSelection.virtualSelectAll || selectAllState.intermediate;
    setDoeCampaignsSelection({
      virtualSelectAll: updatedVirtualSelectAll,
      excludedCampaignsIds: [],
      selectedCampaignsIds: [],
    });
  };

  const onCampaignSelection = (campaignId: number) => {
    if (doeCampaignsSelection.virtualSelectAll) {
      if (excludedCampaignsIds.has(campaignId)) {
        excludedCampaignsIds.delete(campaignId);
      } else {
        excludedCampaignsIds.add(campaignId);
      }
    } else if (selectedCampaignsIds.has(campaignId)) {
      selectedCampaignsIds.delete(campaignId);
    } else {
      selectedCampaignsIds.add(campaignId);
    }

    setDoeCampaignsSelection({
      ...doeCampaignsSelection,
      excludedCampaignsIds: [...excludedCampaignsIds],
      selectedCampaignsIds: [...selectedCampaignsIds],
    });
  };

  const getSelectedConflictedCampaigns = () => {
    const conflictedCampaigns = doeCampaigns.filter(
      (campaign) => campaign.alert === CampaignAlert.Conflicted && !campaign.localSchedule,
    );
    return doeCampaignsSelection.virtualSelectAll
      ? totalNonLocalConflicted - conflictedCampaigns.filter((campaign) => excludedCampaignsIds.has(campaign.id)).length
      : conflictedCampaigns.filter((campaign) => selectedCampaignsIds.has(campaign.id)).length;
  };

  const getSelectedNotEligibleForSelfApprovalCampaigns = () => {
    if (isEligibleForSelfApproval || bulkAction !== CampaignBulkAction.BulkApprove) {
      return 0;
    }

    const notEligibleForSelfApprovalCampaigns = doeCampaigns.filter(
      (campaign) =>
        campaign.alert !== CampaignAlert.Conflicted &&
        !campaign.localSchedule &&
        (campaign.updatedBy.id === user.id || campaign.createdBy.id === user.id),
    );
    return doeCampaignsSelection.virtualSelectAll
      ? totalNotEligibleForSelfApproval -
          notEligibleForSelfApprovalCampaigns.filter((campaign) => excludedCampaignsIds.has(campaign.id)).length
      : notEligibleForSelfApprovalCampaigns.filter((campaign) => selectedCampaignsIds.has(campaign.id)).length;
  };

  const getSelectedLocalCampaigns = () => {
    const localCampaigns = doeCampaigns.filter((campaign) => campaign.localSchedule);
    return doeCampaignsSelection.virtualSelectAll
      ? totalLocal - localCampaigns.filter((campaign) => excludedCampaignsIds.has(campaign.id)).length
      : localCampaigns.filter((campaign) => selectedCampaignsIds.has(campaign.id)).length;
  };

  const getTotalSelectedCampaigns = () =>
    doeCampaignsSelection.virtualSelectAll
      ? total - doeCampaignsSelection.excludedCampaignsIds.length
      : doeCampaignsSelection.selectedCampaignsIds.length;

  const getCampaignsSelectionSummary = () => {
    const campaignSelectionSummary = { total: 0, local: 0, conflicted: 0, notEligibleForSelfApproval: 0, invalid: 0 };
    if (!doeCampaignsSelection) {
      return campaignSelectionSummary;
    }

    campaignSelectionSummary.total = getTotalSelectedCampaigns();
    campaignSelectionSummary.local = getSelectedLocalCampaigns();
    campaignSelectionSummary.conflicted = getSelectedConflictedCampaigns();
    campaignSelectionSummary.notEligibleForSelfApproval = getSelectedNotEligibleForSelfApprovalCampaigns();
    campaignSelectionSummary.invalid = 0;
    return campaignSelectionSummary;
  };

  const getIsChecked = (campaignId: number): boolean =>
    (doeCampaignsSelection.virtualSelectAll && !excludedCampaignsIds.has(campaignId)) ||
    selectedCampaignsIds.has(campaignId);

  useEffect(() => {
    if (!Object.keys(urlFilters.params).length) {
      urlFilters.filterMulti(filters);
    }
  }, []);

  return (
    <PageContainer>
      <StyledHeader data-automation-id="header">
        <TextPageTitle text={tabName.DoeCampaigns} />
        <Actions data-automation-id="actions">
          <CampaignsFilterBar
            offerSource={OfferSource.DOE}
            filter={urlFilters.filter}
            filterMulti={urlFilters.filterMulti}
            filterWithClear={urlFilters.filterWithClear}
            forceStatusFilter={forceStatusFilter}
            onSortModelChange={onSortModelChange}
          />
          <ActionButtonsContainer>
            <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin]}>
              <CampaignBulkActions
                bulkAction={bulkAction}
                offerSource={OfferSource.DOE}
                onBulkActionChange={onBulkActionChange}
                onBulkActionSubmit={onBulkActionSubmit}
                selectedCampaigns={getCampaignsSelectionSummary()}
                bulkUpdateInProgress={false}
              />
            </RoleGuard>
          </ActionButtonsContainer>
        </Actions>
      </StyledHeader>
      <TableContainer>
        <Tooltip id="campaign-lock-icon" onHover content="Locked for changes" place="bottom" />
        <TableHeader>
          <QuickFilters filterWithClear={urlFilters.filterWithClear} offerSource={OfferSource.DOE} />
          <StyledTotalAmountContainer>
            <StyledTotalAmountWithTable amount={total} prefixText="Total results of:" />
          </StyledTotalAmountContainer>
        </TableHeader>
        <StyledTable
          tableProps={{
            ...getCampaignsTableProps(
              onActionClick,
              dateTimeConfig,
              null,
              false,
              bulkAction
                ? {
                    getIsChecked,
                    getSelectAllState,
                    onCampaignSelection,
                    onSelectAll,
                  }
                : null,
              OfferSource.DOE,
            ),

            rows: getDoeCampaignsDetails ?? [],
            loading: loading || bulkCampaignsRevokeOrStopAssociationState.loading,
          }}
          onRowOver={(e: React.SyntheticEvent<HTMLDivElement>) => showActionsCell(e.currentTarget.dataset.id)}
          onRowOut={(e: React.SyntheticEvent<HTMLDivElement>) => hideActionsCell(e.currentTarget.dataset.id)}
          onRowClick={(gridRowParams: any) => onActionClick(ActionType.View, gridRowParams)}
          onRowsScrollEnd={fetchNextCampaigns}
          onSortModelChange={onSortModelChange}
          gridSortModel={gridSortModel}
        />
      </TableContainer>
    </PageContainer>
  );
};

export default DoeCampaigns;
