import { ApprovalStatus } from 'utils/types';
import { BaseOfferVersion } from 'utils/types/offers';
import { BaseCampaignApproval, CampaignAlert, CampaignType } from 'utils/types/campaigns';
import { Tag } from 'utils/types/tags';
import { RestaurantGroup } from 'utils/types/locations';
import { GamePlatform, GamingVoucherType } from 'utils/types/games';
import { User } from 'utils/types/users';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';

export enum CampaignFilterType {
  Status = 'status',
  Type = 'type',
  Tags = 'tags',
  Alert = 'alert',
  SearchQuery = 'searchQuery',
  Id = 'id',
  ExcludedExternalIds = 'excludedExternalIds',
  Period = 'period',
  Zone = 'zone',
  OnlyLocal = 'onlyLocal',
  National = 'national',
  OfferSource = 'offerSource',
  OfferGroup = 'offerGroup',
  OfferBank = 'offerBank',
  Priority = 'priority'
}

export enum FormSection {
  Offer = 'offer',
  GeneralDetails = 'generalDetails',
  Schedule = 'schedule',
  Locations = 'locations',
}

export enum DistributionType {
  Digital = 'digital',
  Print = 'print',
}

export enum RecurrenceType {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Lifetime = 'lifetime',
}

export enum Weekly {
  Calendar = 'calendar',
  Campaign = 'campaign',
}

export interface Recurrence {
  type: RecurrenceType;
  weekly?: Weekly;
  redemptionNumber: number;
}
export interface CampaignProps {
  id: number;
  externalId: number;
  type: CampaignType;
  title: string;
  description?: string;
  status: ApprovalStatus;
  alert?: CampaignAlert;
  redemption?: number;
  tags: Tag[];
  isPriority?: number,
  isTopPriority?: boolean,
  offerVersion: BaseOfferVersion;
  restaurantEligibility?: {
    restaurants: number[];
    restaurantGroups: RestaurantGroup[];
    excludeRestaurants: number[];
    excludeRestaurantGroups: RestaurantGroup[];
    isNationwide?: boolean;
  };
  isArchive?: boolean;
  isDraft?: boolean;
  isLocked?: boolean;
  approvals?: BaseCampaignApproval[];
  schedule: {
    campaignEnd: string | Date;
    campaignStart: string | Date;
    validDays: string[];
    validityEndTime: string | Date;
    validityStartTime: string | Date;
    expirationPeriod?: number;
    recurrence?: Recurrence[];
  };
  voucherConfig?: {
    gaming: boolean;
    platform: GamePlatform;
    distribution: DistributionType;
    gamingType: GamingVoucherType;
    game: string;
    quantity: number;
    startDateTime: string | Date;
    endDateTime: string | Date;
  };
  redemptions: number;
  localSchedule?: {
    zone: any;
    period: any;
  };
  isTriggerEvent?: boolean;
  updatedAt: string;
  updatedBy: User;
  createdBy: User;
  push_notification?: {
    startDate: string | Date;
    push_notification_translation?: [];
  };
  inProgress?: string | null;
  totalLocations?: number;
  oasAudienceId?: string[];
  segmentId?: string[];
  offerSource: OfferSource;
}

export interface CampaignCreationProps extends Omit<CampaignProps, 'schedule'> {
  schedule: {
    campaignEnd?: string | Date;
    campaignStart?: string | Date;
    validDays: string[];
    validityEndTime: string | Date;
    validityStartTime: string | Date;
    expirationPeriod?: number;
    recurrence?: Recurrence[];
    dates?: Date[];
    duration?: number;
  };
}

export interface UrlFilters {
  filter?: (name: string, value: any) => void;
  filterWithClear?: (name: string, value: any) => void;
  filterMulti?: (filters: any) => void;
  params?: any;
}

export interface CampaignsSelection {
  virtualSelectAll: boolean;
  excludedCampaignsIds?: number[];
  selectedCampaignsIds?: number[];
}

export interface BulkCampaignsStatusUpdateDto {
  campaignsSelection: CampaignsSelection;
  status: string;
  filters?: { [key: string]: any };
  total: number;
}

export interface CampaignReportProps {
  id: number;
  externalId: number;
  localSchedule?: LocalSchedule;
  offerVersion: BaseOfferVersion;
  push_notification?: PushNotification;
  schedule: {
    campaignEnd: string | Date;
    campaignStart: string | Date;
    validityEndTime: string | Date;
    validityStartTime: string | Date;
  };
  status: ApprovalStatus;
  title: string;
  type: CampaignType;
}

export interface LocalSchedule {
  approvalStatus?: any;
  period?: {
    name: string;
    startDate: string | Date;
    endDate: string | Date;
  };
  zone?: {
    name: string;
  };
}

export interface PushNotification {
  startDate: string | Date;
  push_notification_translation?: {
    language: string;
    title: string;
    message: string;
  }[];
}
