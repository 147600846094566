import { campaignsGqls } from 'pages/campaigns/campaignManagement/Campaigns.gqls';
import offersGqls from 'pages/offers/offerManagement/Offers.gqls';
import { client } from 'app/apollo';
import { termsConditionsGqls } from 'pages/settings/termsConditions/TermsConditions.gqls';
import { TermConditionProps } from 'pages/settings/termsConditions/TermsConditions.consts';
import { clientQuery } from './clientQuery';
import { OrderDirection } from 'utils/types';

export const updateTermCondition = async (term: TermConditionProps) => {
  return client.mutate({
    mutation: termsConditionsGqls.mutations.update,
    variables: { data: term },
  });
};

export const getUnarchivedOffers = async (termId: number) => {
  const filters = {
    bool: {
      must: [
        {
          bool: {
            should: [{ match: { 'versions.term.id': termId } }, { match: { 'versions.term.originalTermId': termId } }],
          },
        },
        { match: { isArchive: false } },
      ],
    },
  };

  const unarchiveOfferImpacts = await clientQuery(offersGqls.queries.getForImpact, {
    data: {
      filters,
      limit: 1,
    },
  });
  return unarchiveOfferImpacts.data.getOffers.items;
};

export const getUnarchivedCampaigns = async (termId: number) => {
  const filters = {
    bool: {
      must: [
        {
          bool: {
            should: [{ match: { 'offerVersion.term.id': termId } }, { match: { 'offerVersion.term.originalTermId': termId } }],
          },
        },
        { match: { isArchive: false } },
      ],
    },
  };
  const unarchiveCampaignImpacts = await clientQuery(campaignsGqls.queries.getForImpact, {
    data: {
      filters,
      limit: 1,
    },
  });
  return unarchiveCampaignImpacts.data.getCampaigns.items;
};
export const getOffersImpactsByTermId = async (termId: number, limit: number = 9999) => {
  const filters = {
    bool: {
      should: [{ match: { 'versions.term.id': termId } }, { match: { 'versions.term.originalTermId': termId } }],
    },
  };
  const offerImpacts = await clientQuery(offersGqls.queries.getForImpact, {
    data: {
      filters,
      limit,
      order: {
        id: OrderDirection.DESC,
      },
    },
  });

  return offerImpacts.data.getOffers.items;
};

export const getCampaignsImpactsByTermId = async (termId: number, limit: number = 9999) => {
  const filters = {
    bool: {
      should: [
        { match: { 'offerVersion.term.id': termId } },
        { match: { 'offerVersion.term.originalTermId': termId } },
      ],
    },
  };
  const campaignImpacts = await clientQuery(campaignsGqls.queries.getForImpact, {
    data: {
      filters,
      limit,
      ...(limit === 9999 && { isBatchingRequired: true }),
      order: {
        externalId: OrderDirection.DESC,
      },
    },
  });

  return campaignImpacts.data.getCampaigns.items;
};
