import React, { useEffect, useState } from 'react';
import { FilterIcon, Filters, StyledFilterBarSelect } from 'pages/shared/shared.style';
import { useUrlFilters } from 'hooks/use-url-filters';
import {
  AuditAction,
  auditActionRecord,
  auditActions,
  AuditFilters,
  AuditType,
  auditTypes,
  auditTypesRecord,
  auditActionsByType,
  auditTypesByAction,
} from 'pages/reports/audit/Audit.consts';
import { SearchTextField } from 'components/shared/searchTextField/SearchTextField';
import { store } from 'app/store';
import { audit as auditSlice, setFilters } from 'app/slices/audit';
import { useSelector } from 'react-redux';
import { usePersistCaretPosition } from 'hooks/use-persist-caret-position';
import { extractSetFromSelectedItems, isUpdateRequired } from 'utils/audit.util';
import { marketConfig } from 'app/slices/config';

export const AuditFilterBar = () => {
  const { config } = useSelector(marketConfig);
  const { enableDigitalOffersEngine } = config;
  const urlFilters = useUrlFilters((params: any) => {
    store.dispatch(
      setFilters(
        Object.keys(params).reduce(
          (res: any, key: any) => ({ ...res, [key]: Array.isArray(params[key]) ? params[key] : [params[key]] }),
          {},
        ),
      ),
    );
  });
  const { filters } = useSelector(auditSlice);
  const [selectedAuditType, setSelectedAuditType] = useState(auditTypes as any[]);
  const [selectedAuditAction, setSelectedAuditAction] = useState(auditActions as any[]);
  const [caretPosition, setCaretPosition] = usePersistCaretPosition(filters[AuditFilters.SearchQuery]);
  const handleAuditTypeChange = (selectedTypes: any[]) => {
    const typeSet = extractSetFromSelectedItems(selectedTypes, auditActionsByType);
    removeRedundantParams(AuditFilters.Action, typeSet);
    setSelectedAuditAction(Array.from(typeSet).map((typ) => auditActionRecord[typ]));
  };

  const handleAuditActionChange = (selectedActions: any) => {
    const actionSet = extractSetFromSelectedItems(selectedActions, auditTypesByAction);
    removeRedundantParams(AuditFilters.Type, actionSet);
    setSelectedAuditType(Array.from(actionSet).map((act) => auditTypesRecord[act]));
  };

  const removeRedundantParams = (filter: AuditFilters, set: Set<any>) => {
    const filterParam = urlFilters.params?.[filter];
    if (filterParam) {
      const modifiedParam = Array.isArray(filterParam) ? filterParam : [filterParam];
      if (isUpdateRequired(modifiedParam, set)) {
        urlFilters.filter(
          filter,
          modifiedParam.filter((param) => set.has(param)),
        );
      }
    }
  };

  useEffect(() => {
    if (!Object.keys(urlFilters.params).length) {
      urlFilters.filterMulti(filters);
    } else {
      const { type, action } = Object.keys(urlFilters.params).reduce(
        (res: any, key: any) => ({
          ...res,
          [key]: Array.isArray(urlFilters.params[key]) ? urlFilters.params[key] : [urlFilters.params[key]],
        }),
        {},
      );
      if (type?.length) {
        handleAuditTypeChange(type.map((typ: AuditType) => auditTypesRecord[typ]));
      }
      if (action?.length) {
        handleAuditActionChange(action.map((act: AuditAction) => auditActionRecord[act]));
      }
    }
  }, []);

  useEffect(() => {
    const { action } = filters;
    if (action?.length) {
      handleAuditActionChange(action.map((act: AuditAction) => auditActionRecord[act]));
    }
  }, [filters?.action]);

  useEffect(() => {
    const { type } = filters;
    if (type?.length) {
      handleAuditTypeChange(type.map((typ: AuditType) => auditTypesRecord[typ]));
    }
  }, [filters?.type]);

  return (
    <Filters>
      <FilterIcon name="filter" />
      <StyledFilterBarSelect
        placeholder="Select"
        label="Type"
        name="type"
        multiple
        items={
          enableDigitalOffersEngine
            ? selectedAuditType
            : selectedAuditType.filter(
                (item) => ![AuditType.DOECampaign, AuditType.DOEOffer, AuditType.DOEImage].includes(item.id),
              )
        }
        onChange={(selectedItems: any) => {
          urlFilters.filter(
            AuditFilters.Type,
            Object.values(selectedItems).map((i: any) => i.id),
          );
          if (!selectedItems.length) {
            setSelectedAuditAction(auditActions);
          }
        }}
        initialSelectedItems={filters[AuditFilters.Type]}
        reset
        maxItems={1}
        selectWidth={160}
      />
      <StyledFilterBarSelect
        placeholder="Select"
        label="Action"
        name="action"
        multiple
        items={selectedAuditAction}
        onChange={(selectedItems: any) => {
          urlFilters.filter(
            AuditFilters.Action,
            Object.values(selectedItems).map((i: any) => i.id),
          );
          if (!selectedItems.length) {
            setSelectedAuditType(auditTypes);
          }
        }}
        initialSelectedItems={filters[AuditFilters.Action]}
        reset
        maxItems={1}
        selectWidth={160}
      />
      <SearchTextField
        key={`${JSON.stringify(filters[AuditFilters.SearchQuery])}_SearchQuery`}
        name="audit-search"
        caretPosition={caretPosition}
        value={filters[AuditFilters.SearchQuery] ?? ''}
        onChange={(e) => {
          urlFilters.filter(AuditFilters.SearchQuery, e.target.value);
          setCaretPosition(e);
        }}
      />
    </Filters>
  );
};
