import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { DiscountTypeRecord } from 'pages/offers/offerManagement/components/offerForm/components/templates/Template.consts';
import useDoeDiscountType from '../hooks/doe-discount-type';
import { Currency } from '../../../../../../offers/offerManagement/components/offerForm/components/templates/shared/Currency/Currency';
import Checkbox from 'components/shared/checkbox/Checkbox';
import { DiscountComponentProps, CheckboxState } from './DiscountComponent.consts';
import {
  CurrencyContainer,
  DiscountComponentContainer,
  DiscountComponentRow,
  StyledLabel,
  StyledSelectbox,
  StyledTextField,
} from './DiscountComponent.style';

export const DiscountComponent = ({ disabled }: DiscountComponentProps) => {
  const {
    control,
    register,
    getValues,
    setValue,
    unregister,
    formState: { errors },
  } = useFormContext();

  const { discountType, isFree, isPercent, shouldDisplayCurrency } = useDoeDiscountType();
  const { discountComponentCheckbox } = getValues()?.image;
  const [checkboxState, setCheckboxState] = useState<CheckboxState>(
    discountComponentCheckbox ? discountComponentCheckbox : { checked: true },
  );

  // Function to handle checkbox change
  const handleCheckboxChange = (isChecked: boolean | undefined = !checkboxState.checked) => {
    const newCheckboxState = { checked: isChecked };
    setCheckboxState(newCheckboxState);
    setValue('image.discountComponentCheckbox', newCheckboxState);
    setValue('image.metadata.discount.minPurchase', undefined, { shouldDirty: true });
    unregister('image.metadata.discount.minPurchase');
  };

  useEffect(() => {
    if (!discountType) {
      handleCheckboxChange(true);
    }
  }, [discountType]);

  return (
    <DiscountComponentContainer>
      <DiscountComponentRow>
        <StyledSelectbox
          label="Discount Type"
          name="image.metadata.discount.discountType"
          disabled={disabled}
          control={control}
          items={
            Object.entries(DiscountTypeRecord()).map(([key, value]) => {
              return {
                id: key,
                name: value,
              };
            }) as any[]
          }
          selectWidth={100}
          reset
          onChange={(selectedItem: any) => {
            setValue('image.metadata.discount.discountType', selectedItem ? selectedItem.id : null);
          }}
        />
        {!isFree && (
          <CurrencyContainer>
            {shouldDisplayCurrency && <Currency />}
            <StyledTextField
              disabled={disabled || !discountType}
              register={register}
              validation={
                discountType
                  ? {
                      required: true,
                      min: isPercent ? 1 : 0.01,
                      max: isPercent ? 99 : null,
                      pattern: isPercent ? /^([1-9][0-9]?|99)$/ : /^\d+(?:\.\d{1,2})?$/,
                      validate: (v: string) =>
                        (!Number.isNaN(Number(v)) && Number(v) >= 0.01) ||
                        (isPercent && Number(v) < 100 && Number(v) >= 1),
                    }
                  : {}
              }
              errors={errors}
              name="image.metadata.discount.value"
              placeholder="Enter value"
            />
          </CurrencyContainer>
        )}
      </DiscountComponentRow>
      <DiscountComponentRow>
        <Checkbox
          checked={!checkboxState.checked}
          onClick={() => handleCheckboxChange()}
          label="Minimum Purchase"
          disabled={disabled || !discountType}
        />
        <StyledLabel disabled={disabled}>of</StyledLabel>
        <CurrencyContainer>
          {shouldDisplayCurrency && <Currency />}
          <StyledTextField
            disabled={checkboxState.checked || disabled}
            register={register}
            validation={
              !checkboxState.checked
                ? {
                    required: true,
                    min: 0.01,
                    max: null,
                    pattern: /^\d+(?:\.\d{1,2})?$/,
                    validate: (v: string) => !Number.isNaN(Number(v)) && Number(v) >= 0.01,
                  }
                : {}
            }
            errors={errors}
            name="image.metadata.discount.minPurchase"
            placeholder="Enter value"
          />
        </CurrencyContainer>
      </DiscountComponentRow>
    </DiscountComponentContainer>
  );
};

export default DiscountComponent;
