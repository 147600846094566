import React, { useEffect, useState } from 'react';
import { ApprovalStatus, FormMode, GenericEntity, OrderDirection, TimeFormats } from 'utils/types';
import { CampaignType } from 'utils/types/campaigns';
import ToggleSwitch from 'components/shared/toggleSwitch/ToggleSwitch';
import { RecurrenceType, Weekly } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import { capitalize } from 'utils/text';
import { isInArray } from 'utils/array';
import { useFieldArray, useFormContext, useFormState, useWatch } from 'react-hook-form';
import {
  getCalenderWeekDays,
  getMarketDatetime,
  getMarketNextDayDatetime,
  convertUtcDateToTimezoneDate,
  convertUtcDateToTimezoneDateUsingLibrary,
  maxDate,
} from 'utils/date';
import { shouldShowFieldInViewMode } from 'pages/campaigns/campaignManagement/components/campaignForm/CampaignForm.utils';
import RadioGroup from 'components/shared/radioGroup/RadioGroup';
import { ButtonText } from 'components/shared/button/buttonText';
import useToggle from 'hooks/use-toggle';
import { Game } from 'utils/types/games';
import { campaignsGqls } from 'pages/campaigns/campaignManagement/Campaigns.gqls';
import { useQuery } from '@apollo/client';
import { periodsGqls } from 'pages/settings/schedulePeriods/SchedulePeriods.gqls';
import { PeriodProps } from 'pages/settings/schedulePeriods/SchedulePeriods.consts';
import Error from 'components/shared/error/Error';
import {
  DateToShown,
  ScheduleSectionProps,
  validityDays,
} from 'pages/campaigns/campaignManagement/components/campaignForm/components/scheduleSection/ScheduleSection.consts';
import {
  BulkSection,
  DatesSelection,
  ExpirationDate,
  MultipleDatesSection,
  MultipleDatesToggle,
  NumberField,
  RecurrenceRow,
  RecurrenceSelect,
  Redemption,
  RedemptionsText,
  ScheduleContainer,
  Section,
  SectionContainer,
  SelectedDatesSection,
  StyledDateTimePicker,
  StyledEditIcon,
  StyledIcon,
  StyledMultipleChoice,
  StyledRemoveIcon,
  StyledTimePicker,
  SubHeader,
  ToggleTitle,
} from 'pages/campaigns/campaignManagement/components/campaignForm/components/scheduleSection/ScheduleSection.style';
import { Selectbox } from 'components/shared/selectbox/Selectbox';
import useFeatureFlag from 'hooks/use-feature-flag';
import { Feature } from 'utils/types/features';
import Checkbox from 'components/shared/checkbox/Checkbox';
import { store } from 'app/store';
import { Modals, openModal } from 'app/slices/modals';
import InfoTooltip from 'components/shared/tooltip/info/InfoTooltip';
import { getIsControlWithError } from 'utils/form';
import { isObject } from 'utils/object';
import PushNotificationSection from '../pushNotification/PushNotificationSection';
import { FetchPolicies, ValidationMessages } from 'utils/types/common';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';

const currDate = new Date().toISOString();

const ScheduleSection = ({
  mode,
  status,
  isDisabled,
  dateTimeConfig,
  className,
  offerSource = OfferSource.VCE,
}: ScheduleSectionProps) => {
  const { timeFormat, startTimezone, endTimezone, calendarWeekStartsOn } = dateTimeConfig;
  const { register, unregister, control, watch, trigger, setValue, getValues } = useFormContext();
  const { errors } = useFormState();
  const maxRecurrenceLength = Object.values(RecurrenceType).length;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'schedule.recurrence',
  });
  const scheduleDatesPath='schedule.dates';
  const scheduleDurationPath='schedule.duration';
  const scheduleCampaignStartPath = 'schedule.campaignStart';
  const scheduleCampaignEndPath = 'schedule.campaignEnd';
  const localSchedulePeriodPath = 'localSchedule.period';
  const [isWithRecurrence, setIsWithRecurrence] = useToggle(Boolean(fields.length));
  const isCampaignBulkOn = useFeatureFlag(Feature.CampaignBulk);
  const [isTriggerEvent, dates, duration] = watch(['isTriggerEvent', scheduleDatesPath, scheduleDurationPath]);
  const [isMultipleDates, setIsMultipleDates] = useToggle(
    mode === FormMode.New && isCampaignBulkOn && (duration || dates || isTriggerEvent),
  );
  const [
    campaignStart,
    campaignEnd,
    validDays,
    validityStartTime,
    validityEndTime,
    recurrenceWatch,
    period,
    zoneSelection,
    isNational
  ] = watch([
    scheduleCampaignStartPath,
    scheduleCampaignEndPath,
    'schedule.validDays',
    'schedule.validityStartTime',
    'schedule.validityEndTime',
    'schedule.recurrence',
    localSchedulePeriodPath,
    'localSchedule.zone',
    'isNational'
  ]);
  const [isLocalCampaign, isGeneratedName] = watch(['isLocalCampaign', 'isGeneratedName']);
  const selectedGameName = useWatch({ control, name: 'voucherConfig.game' });
  const doeEditDisabled = (mode === FormMode.Edit && offerSource === OfferSource.DOE);
  const { data: gamesData } = useQuery<{ getGames: GenericEntity<Game> }>(campaignsGqls.queries.getGames, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    skip: !selectedGameName,
    variables: {
      data: {
        filters: {
          AND: [{ name: { is: selectedGameName } }],
        },
      },
    },
  });
  const selectedGame = gamesData?.getGames.items?.[0];
  const { data: periodsData } = isNational ? (
    useQuery(periodsGqls.queries.getPeriodsForNational, {
      fetchPolicy: FetchPolicies.CacheAndNetwork,
      nextFetchPolicy: FetchPolicies.CacheAndNetwork,
      notifyOnNetworkStatusChange: true,
      skip: mode === FormMode.View,
    })) : (useQuery(periodsGqls.queries.getPeriodsForZone, {
      fetchPolicy: FetchPolicies.CacheAndNetwork,
      nextFetchPolicy: FetchPolicies.CacheAndNetwork,
      notifyOnNetworkStatusChange: true,
      skip: mode === FormMode.View || !zoneSelection,
      variables: {
        zoneId: isObject(zoneSelection) ? Number(zoneSelection?.id) : Number(zoneSelection),
      },
    }))
  let periods: PeriodProps[];
  if (isNational) {
    const { getPeriodsForNational } = periodsData || { getPeriodsForNational: [] };
    periods = getPeriodsForNational;
  } else {
    const { getPeriodsForZone } = periodsData || { getPeriodsForZone: [] };
    periods = getPeriodsForZone;
  }
  const calendarWeekDays = getCalenderWeekDays(validityDays, calendarWeekStartsOn);
  const withAmPm = timeFormat === TimeFormats.Until12;
  const shouldDisplayAddRecurrence = isWithRecurrence && fields.length < maxRecurrenceLength && !isDisabled;
  const shouldDisplayRemoveRecurrence = isWithRecurrence && fields.length > 1 && !isDisabled;
  const isAfterGameStart =
    startTimezone &&
    selectedGame &&
    getMarketDatetime(startTimezone).getTime() >
      convertUtcDateToTimezoneDate(selectedGame.startDateTime, startTimezone).getTime();
  const [selectedPeriodData, setSelectedPeriodData] = useState(null);

  const shouldValidateGameDates =
    selectedGame &&
    isInArray([ApprovalStatus.Rejected, ApprovalStatus.Revoked, ApprovalStatus.AssociationStopped], status);

  const setRecurrenceArray = () => {
    if (!recurrenceWatch?.length) {
      append({ type: RecurrenceType.Daily, redemptionNumber: 1 });
    }
  };

  const resetRecurrenceArray = () => {
    fields.forEach(() => {
      remove();
    });
  };

  const handleRecurrenceArray = () => {
    if (isWithRecurrence) {
      setRecurrenceArray();
    } else {
      resetRecurrenceArray();
    }
  };
  const periodSelected = period ? periods.find((p: PeriodProps) => p.id === period): null;
  const dateToShown: DateToShown = {};
  if (periodSelected) {
    dateToShown.startDate = convertUtcDateToTimezoneDateUsingLibrary(periodSelected.startDate, startTimezone);
    dateToShown.endDate = convertUtcDateToTimezoneDateUsingLibrary(periodSelected.endDate, endTimezone);
  }
  const onSelectDatesClick = () => {
    store.dispatch(
      openModal({
        modal: Modals.MultipleDatesSelectionModal,
        props: {
          isTriggerEvent,
          selectedDates: dates,
          onSave: (newDates: Date[]) =>
            store.dispatch(
              openModal({
                modal: Modals.CampaignModal,
                props: {
                  mode,
                  campaign: {
                    ...getValues(),
                    schedule: { ...getValues('schedule'), dates: newDates },
                    isLocalCampaign,
                    isGeneratedName,
                  },
                },
              }),
            ),
          onCancel: () =>
            store.dispatch(
              openModal({
                modal: Modals.CampaignModal,
                props: { mode, campaign: { ...getValues(), isLocalCampaign, isGeneratedName } },
              }),
            ),
          datesToShow : dateToShown
        },
      }),
    );
  };

  const onMultipleDatesToggle = () => {
    if (!isMultipleDates && isLocalCampaign) {
      setValue('restaurantEligibility.restaurantGroups', undefined);
    }
    setIsMultipleDates();
  };

  const startDateHasError = getIsControlWithError(scheduleCampaignStartPath, errors);
  const campaignDates = (
    <ScheduleContainer hasExtraMargin={startDateHasError}>
      <StyledDateTimePicker
        control={control}
        name="schedule.campaignStart"
        label="Start"
        disabled={isDisabled || doeEditDisabled}
        errors={errors}
        timezone={startTimezone}
        validation={
          !isDisabled ||
          isInArray([ApprovalStatus.Draft, ApprovalStatus.PendingApproval, ApprovalStatus.Rejected], status) ||
          shouldValidateGameDates
            ? {
                required: ValidationMessages.RequiredField,
                validate: {
                  minDate: (date: Date) => {
                    try {
                      const currDateTime = date.getTime();
                      if (Number.isNaN(currDateTime)) {
                        return `Date is not valid`;
                      }
                      if (mode !== FormMode.View && currDateTime < getMarketDatetime(startTimezone).getTime()) {
                        return `Start time is in the past`;
                      }
                      if (
                        selectedGame &&
                        currDateTime >
                          new Date(convertUtcDateToTimezoneDate(selectedGame.endDateTime, endTimezone)).getTime()
                      ) {
                        return `Campaign Start Date must be before Game End Date`;
                      }

                      if (isLocalCampaign && selectedPeriodData) {
                        if (
                          date < convertUtcDateToTimezoneDate(selectedPeriodData.startDate,startTimezone) ||
                          date > convertUtcDateToTimezoneDate(selectedPeriodData.endDate,endTimezone)
                        ) {
                          return 'Start Date must be within the selected period';
                        }
                      }
                    } catch (error) {
                      return `Date is not valid`;
                    }
                  },
                },
              }
            : {}
        }
        withAmPm={withAmPm}
        defaultValue={getMarketNextDayDatetime(startTimezone, true)}
        minDate={
          isLocalCampaign && selectedPeriodData
            ? convertUtcDateToTimezoneDateUsingLibrary(selectedPeriodData.startDate, startTimezone)
            : getMarketDatetime(startTimezone)
        }
        maxDate={
          isLocalCampaign && selectedPeriodData
            ? convertUtcDateToTimezoneDateUsingLibrary(
              selectedPeriodData.endDate,
                endTimezone,
              )
            : selectedGame?.endDateTime
            ? convertUtcDateToTimezoneDate(selectedGame.endDateTime, endTimezone)
            : undefined
        }
      />
      <StyledDateTimePicker
        control={control}
        withAmPm={withAmPm}
        label="End"
        errors={errors}
        timezone={endTimezone}
        validation={
          mode !== FormMode.View || shouldValidateGameDates
            ? {
                required: ValidationMessages.RequiredField,
                validate: {
                  minDate: (date: Date) => {
                    const currTimestamp = date.getTime();
                    const minDate = new Date(campaignStart).getTime();
                    if (Number.isNaN(currTimestamp)) {
                      return `Date is not valid`;
                    }
                    if (shouldValidateGameDates && mode === FormMode.View 
                    && currTimestamp < getMarketDatetime(endTimezone).getTime()) {
                      return ``;
                    }
                    if (campaignStart && minDate >= currTimestamp) {
                      return `End time must be after start time`;
                    }
                    if (currTimestamp < getMarketDatetime(endTimezone).getTime()) {
                      return `End time is in the past`;
                    }
                    if (
                      selectedGame &&
                      currTimestamp < convertUtcDateToTimezoneDate(selectedGame.startDateTime, startTimezone).getTime()
                    ) {
                      return `Campaign End Date must be after Game Start Date`;
                    }
                  },
                },
              }
            : {
              required: ValidationMessages.RequiredField,
                validate: {
                  minDate: (date: Date) => {
                    const endTime = date.getTime();
                    if (endTime < getMarketDatetime(endTimezone).getTime()) {
                      return ``;
                    }
                  },
                }
            }
        }
        disabled={mode === FormMode.View || (mode === FormMode.Edit && isAfterGameStart)}
        name="schedule.campaignEnd"
        defaultValue={getMarketNextDayDatetime(endTimezone, false)}
        minDate={selectedGame?.startDateTime ? maxDate(convertUtcDateToTimezoneDate(selectedGame.startDateTime, startTimezone), getMarketDatetime(endTimezone)) :
           maxDate(new Date(campaignStart), getMarketDatetime(endTimezone))}
      />
    </ScheduleContainer>
  );

  useEffect(() => {
    if (campaignStart && campaignEnd) {
      trigger(scheduleCampaignStartPath);
      trigger(scheduleCampaignEndPath);
    }
  }, [campaignStart, campaignEnd, selectedGame]);

  useEffect(() => {
    if (validityStartTime && validityEndTime) {
      trigger('schedule.validityStartTime');
      trigger('schedule.validityEndTime');
    }
  }, [validityStartTime, validityEndTime]);

  useEffect(() => {
    if (!isDisabled) {
      handleRecurrenceArray();
    }
  }, [isWithRecurrence]);

  useEffect(() => {
    if (periods && mode !== FormMode.View) {
      setSelectedPeriodData(period ? periods.find((p: PeriodProps) => p.id === period) : null);
    }
  }, [period, periods]);

  useEffect(() => {
    if (selectedPeriodData) {
      if (isInArray([FormMode.New, FormMode.Edit, FormMode.Duplicate], mode)) {
        const start = convertUtcDateToTimezoneDate(selectedPeriodData.startDate,startTimezone);
        const end = convertUtcDateToTimezoneDate(selectedPeriodData.endDate,endTimezone);
        setValue(scheduleCampaignEndPath, end > new Date() ? end : new Date());
        setValue(scheduleCampaignStartPath, start > new Date() ? start : new Date());
      }
    } else if(isLocalCampaign) {
      if (isInArray([FormMode.New, FormMode.Duplicate], mode)) {
        setValue(scheduleCampaignStartPath, getMarketNextDayDatetime(startTimezone, true));
        setValue(scheduleCampaignEndPath, getMarketNextDayDatetime(endTimezone, false));
      }
    } else if (isMultipleDates && isInArray([FormMode.New], mode)) {
      setValue(scheduleCampaignStartPath, getMarketNextDayDatetime(startTimezone, true));
      setValue(scheduleCampaignEndPath, getMarketNextDayDatetime(endTimezone, false));
    }
    trigger(scheduleCampaignStartPath);
    trigger(scheduleCampaignEndPath);
  }, [selectedPeriodData, isMultipleDates]);

  useEffect(() => {
    if (
      isInArray([FormMode.Edit, FormMode.Duplicate], mode) &&
      period &&
      new Date() > new Date(period.endDate)
    ) {
      setValue(localSchedulePeriodPath, null);
      trigger(localSchedulePeriodPath);
    }
    if (mode === FormMode.Duplicate) {
      trigger('schedule');
    }
  }, [mode]);

  useEffect(() => {
    if (isMultipleDates) {
      register('isTriggerEvent', { value: false });
      register(scheduleDatesPath, { required: true });
      register(scheduleDurationPath);
      unregister(scheduleCampaignStartPath);
      unregister(scheduleCampaignEndPath);
    } else {
      unregister('isTriggerEvent');
      unregister(scheduleDatesPath);
      unregister(scheduleDurationPath);
      register(scheduleCampaignStartPath);
      register(scheduleCampaignEndPath);
    }
  }, [isMultipleDates]);

  return (
    <SectionContainer className={className}>
      {isLocalCampaign && !isMultipleDates && (periods || (mode === FormMode.View && period)) && (zoneSelection || isNational) && (
        <Section>
          <Selectbox
            key={JSON.stringify(periods)}
            control={control}
            placeholder={period ? period.name : "Select"}
            name="localSchedule.period"
            label="Period"
            items={mode === FormMode.View ? [period] : periods}
            disabled={isDisabled}
            validation={isLocalCampaign ? { required: ValidationMessages.RequiredField } : {}}
            reset
            selectWidth={240}
            initialSelectedItems={period ? [typeof period === 'object' ? period.id : period] : []}
          />
          {periods.length === 0 && mode !== FormMode.View && <Error errors="No periods are defined" />}
          {period && new Date() > new Date(period.endDate) && (
            <Error errors="Period is already expired" />
          )}
        </Section>
      )}
      <Section>
        <SubHeader>Campaign Date</SubHeader>
        {isCampaignBulkOn && mode === FormMode.New ? (
          <BulkSection>
            <MultipleDatesToggle
              label="Multiple Dates"
              size="small"
              checked={isMultipleDates}
              onClick={onMultipleDatesToggle}
            />
            {isMultipleDates ? (
              <MultipleDatesSection>
                <Checkbox
                  label="Trigger Event"
                  checked={isTriggerEvent}
                  onClick={() => setValue('isTriggerEvent', !isTriggerEvent)}
                />
                {isTriggerEvent && isLocalCampaign && (
                  <InfoTooltip content="Trigger Event does not require period selection." />
                )}
                <DatesSelection>
                  {dates?.length ? (
                    <SelectedDatesSection>
                      <ButtonText onClick={onSelectDatesClick}>
                        <StyledEditIcon name="edit" />
                      </ButtonText>
                      {dates.length} dates selected
                    </SelectedDatesSection>
                  ) : (
                    <ButtonText onClick={onSelectDatesClick}>Select Dates</ButtonText>
                  )}
                </DatesSelection>
                <NumberField
                  register={register}
                  name="schedule.duration"
                  label="Duration"
                  validation={{
                    required: ValidationMessages.RequiredField,
                    pattern: { value: /^[1-7]*$/, message: 'Input must be between 1 and 7' },
                  }}
                  value={1}
                  errors={errors}
                />
              </MultipleDatesSection>
            ) : (
              campaignDates
            )}
          </BulkSection>
        ) : (
          campaignDates
        )}
        {isInArray(
          [CampaignType.Segmented, CampaignType.WelcomeOffer, CampaignType.Ned, CampaignType.CustomerServiceOffer],
          watch('type'),
        ) && (
          <ExpirationDate
            register={register}
            name="schedule.expirationPeriod"
            label="Expiration Period (days)"
            value={0}
            disabled={isDisabled}
            errors={errors}
            validation={{
              validate: (v: string) => {
                if (Number.isNaN(Number(v)) || Number(v) < 0) {
                  return 'Number must be larger than 0';
                }
              },
            }}
          />
        )}
      </Section>
      <Section>
        <SubHeader>Offer Validity</SubHeader>
        <ScheduleContainer>
          <StyledMultipleChoice
            items={calendarWeekDays}
            label="Valid Days"
            validation={
              mode !== FormMode.View
                ? {
                    validate: (days: string[]) => {
                      if (days.length === 0) {
                        return `Must be at least one valid day`;
                      }
                    },
                  }
                : {}
            }
            errors={errors}
            disabled={mode === FormMode.View}
            control={control}
            name="schedule.validDays"
            value={validDays || validityDays.map((day) => day.id)}
          />
          <StyledTimePicker
            withAmPm={withAmPm}
            label="Start"
            errors={errors}
            validation={
              mode !== FormMode.View
                ? {
                    required: ValidationMessages.RequiredField,
                    validate: {
                      minDate: (date: Date) => {
                        const currTimestamp = convertUtcDateToTimezoneDate(date, startTimezone).getTime();
                        const maximumDate = validityEndTime ? new Date(validityEndTime) : null;
                        if (Number.isNaN(currTimestamp)) {
                          return `Time is not valid`;
                        }
                        if (maximumDate?.getTime() <= currTimestamp) {
                          return `Start time can't be later then end time`;
                        }
                      },
                    },
                  }
                : {}
            }
            disabled={mode === FormMode.View}
            name="schedule.validityStartTime"
            control={control}
            defaultValue={validityStartTime ? new Date(validityStartTime) : new Date('2000-01-01T00:00')}
          />
          <StyledTimePicker
            withAmPm={withAmPm}
            label="End"
            errors={errors}
            validation={{
              required: ValidationMessages.RequiredField,
              validate: {
                format: (date: Date) => {
                  const currDateTime = new Date(date).getTime();
                  if (Number.isNaN(currDateTime)) {
                    return `Time is not valid`;
                  }
                },
              },
            }}
            disabled={mode === FormMode.View}
            name="schedule.validityEndTime"
            control={control}
            defaultValue={validityEndTime ? new Date(validityEndTime) : new Date('2000-01-01T23:59')}
          />
        </ScheduleContainer>
      </Section>
      {shouldShowFieldInViewMode(mode, isWithRecurrence) && (
        <Section>
          <SubHeader>
            <ToggleTitle>Recurrence and Limit</ToggleTitle>
            <ToggleSwitch size="small" checked={isWithRecurrence} onClick={setIsWithRecurrence} disabled={isDisabled} />
          </SubHeader>
          {Boolean(recurrenceWatch?.length) &&
            fields.map((recurrence, index) => (
              <RecurrenceRow key={recurrence.id}>
                <RecurrenceSelect
                  control={control}
                  name={`schedule.recurrence.${index}.type`}
                  errors={errors}
                  disabled={isDisabled || doeEditDisabled}
                  items={
                    Object.values(RecurrenceType).map((rec) => ({
                      id: rec,
                      name: capitalize(rec),
                    })) as any[]
                  }
                  validation={{
                    validate: {
                      alreadyExist: (selectedType: RecurrenceType) => {
                        const isSelectedTypeExisted = recurrenceWatch.filter((rec: any) => rec.type === selectedType);
                        if (isSelectedTypeExisted.length > 1) {
                          return `Period already defined`;
                        }
                      },
                      isNonEmpty: (selectedType: string) => {
                        return selectedType !== '';
                      },
                    },
                  }}
                  selectWidth={100}
                  initialSelectedItems={
                    recurrenceWatch[index]?.type
                      ? [
                          typeof recurrenceWatch[index]?.type === 'object'
                            ? recurrenceWatch[index]?.type.id
                            : recurrenceWatch[index]?.type,
                        ]
                      : ['1']
                  }
                />
                <Redemption>
                  <NumberField
                    register={register}
                    name={`schedule.recurrence.${index}.redemptionNumber`}
                    label="limit of"
                    validation={{
                      required: ValidationMessages.RequiredField,
                      pattern: { value: /^[1-9][0-9]*$/, message: 'Number must be larger than 0' },
                    }}
                    errors={errors}
                    disabled={isDisabled || doeEditDisabled}
                  />
                  <RedemptionsText>redemptions</RedemptionsText>
                </Redemption>
                {recurrenceWatch[index]?.type === RecurrenceType.Weekly && (
                  <RadioGroup
                    control={control}
                    name={`schedule.recurrence.${index}.weekly`}
                    radioList={Object.values(Weekly).map((weekly) => ({
                      value: weekly,
                      label: `${capitalize(weekly)} week`,
                    }))}
                    defaultValue={Weekly.Calendar}
                    disabled={isDisabled || doeEditDisabled}
                  />
                )}
                {shouldDisplayRemoveRecurrence && (
                  <ButtonText
                    disabled={doeEditDisabled}
                    onClick={() => {
                      remove(index);
                      recurrenceWatch.splice(index, 1);
                    }}
                  >
                    <StyledRemoveIcon name="minusVector" />
                  </ButtonText>
                )}
              </RecurrenceRow>
            ))}
          {shouldDisplayAddRecurrence && (
            <ButtonText
              onClick={() => {
                append({ type: '', redemptionNumber: 1 });
              }}
              disabled={isDisabled || doeEditDisabled}
            >
              <StyledIcon name="plusVector" />
            </ButtonText>
          )}
        </Section>
      )}
      <PushNotificationSection
        mode={mode}
        status={status}
        isDisabled={isDisabled || doeEditDisabled}
        dateTimeConfig={dateTimeConfig}
      ></PushNotificationSection>
    </SectionContainer>
  );
};

export default ScheduleSection;
