import ToggleSwitch from 'components/shared/toggleSwitch/ToggleSwitch';
import { NotificationTranslation, PushNotificationProps } from './PushNotificationSection.consts';
import {
  FormRow,
  LanguageContentContainer,
  LanguageNavigationContainer,
  LanguageNavigationItem,
  NotificationLanguage,
  NotificationTitleStyledTextField,
  StyledContainer,
  StyledDateTimePicker,
  StyledTextArea,
  SubHeader,
  ToggleTitle,
  StyledTimePicker,
} from './PushNotificationSection.style';
import useToggle from 'hooks/use-toggle';
import { useFieldArray, useFormContext, useFormState } from 'react-hook-form';
import { Language, LanguageRecord } from 'utils/languages';
import { marketConfig } from 'app/slices/config';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { hasValue } from 'utils/text';
import { getMarketDatetime } from 'utils/date';
import { ApprovalStatus, FormMode, TimeFormats } from 'utils/types';
import { isInArray } from 'utils/array';
import { ValidationMessages } from 'utils/types/common';
import { maxDate } from '../../../../../../../utils/date';

const PushNotificationSection = ({
  mode,
  status,
  translationsMap,
  isDisabled,
  dateTimeConfig,
  className,
}: PushNotificationProps) => {
  const { timeFormat, startTimezone } = dateTimeConfig;
  const { register, unregister, control, watch, trigger, setValue, getValues } = useFormContext();
  const { config, languages } = useSelector(marketConfig);
  const [selectedLanguage, setSelectedLanguage] = useState(config.primaryLanguage);
  const [isNotificationTitle] = watch(['isNotificationTitle']);
  useEffect(() => {
    languages.forEach((lang: any, index: number) => {
      setValue(`push_notification_translation.${index}.language`, lang);
    });
  }, [setValue]);
  const { errors } = useFormState();
  const { fields } = useFieldArray({
    control,
    name: 'push_notification.push_notification_translation',
  });
  const initialToggleState = Boolean(fields.length);
  const [isNotification, setNotification] = useToggle(initialToggleState);
  const [isNotificationToggleOn, setIsNotificationToggleOn] = useState(true);
  const withAmPm = timeFormat === TimeFormats.Until12;
  const pushNotificationStartDate = 'push_notification.startDate';
  const [
    campaignStart,
    campaignEnd,
    notificationDate,
    notificationTitle,
    notificationMessage,
  ] = watch([
    'schedule.campaignStart',
    'schedule.campaignEnd',
    'isNotification',
    pushNotificationStartDate,
    `push_notification.push_notification_translation`,
    `push_notification.push_notification_translation.0.title`,
    `push_notification.push_notification_translation.0.message`,
  ]);

  useEffect(() => {
    trigger('schedule.campaignStart');
  }, [notificationDate, notificationTitle, notificationMessage]);

  useEffect(() => {
    languages?.forEach((lang: any, index: number) => {
      setValue(`push_notification_translation.${index}.language`, lang);
    });
  }, [setValue]);

  useEffect(() => {
    if ((mode === FormMode.Edit || mode === FormMode.View) && isNotificationToggleOn) {
      const pushNotificationValue = getValues('push_notification');
      const primaryIndex = pushNotificationValue?.push_notification_translation?.findIndex(
        (translation: { language: any }) => translation?.language === config.primaryLanguage,
      );

      if (primaryIndex !== 0) {
        const primaryTranslation = pushNotificationValue?.push_notification_translation?.splice(primaryIndex, 1)[0];
        pushNotificationValue?.push_notification_translation?.unshift(primaryTranslation);
        setValue('push_notification', pushNotificationValue, { shouldValidate: false });
      }

      if (pushNotificationValue?.startDate !== null) {
        unregister(pushNotificationStartDate);
        setValue(pushNotificationStartDate, pushNotificationValue?.startDate);
      } else {
        unregister(pushNotificationStartDate);
        setValue(pushNotificationStartDate, campaignStart);
      }

      pushNotificationValue?.push_notification_translation?.forEach(
        (translation: { language: any; title: any; message: any }, index: any) => {
          if (selectedLanguage === translation?.language) {
            unregister(`push_notification_translation.${index}.language`);
            unregister(`push_notification_translation.${index}.title`);
            unregister(`push_notification_translation.${index}.message`);

            setValue(`push_notification_translation.${index}.language`, translation?.language);
            setValue(`push_notification_translation.${index}.title`, translation?.title);
            setValue(`push_notification_translation.${index}.message`, translation?.message);
          }
        },
      );
    }
  }, [mode, isNotificationToggleOn]);

  useEffect(() => {
    if (!isNotification) {
      unregister(pushNotificationStartDate);
      unregister('push_notification.push_notification_translation');
    }
    else {
      trigger(pushNotificationStartDate)
    }
  }, [isNotification]);

  useEffect(() => {
    // Preserve toggle switch state when switching between view and edit mode
    setIsNotificationToggleOn(isNotification);
  }, [isNotification]);

  useEffect(() => {
    if (campaignStart && campaignEnd) {
      trigger(pushNotificationStartDate);
    }
  }, [campaignStart, campaignEnd]);
  return (
    <>
      <FormRow>
        <SubHeader>
          <ToggleTitle>Push Notification</ToggleTitle>
          <ToggleSwitch
            size="small"
            checked={isNotification}
            onClick={setNotification}
            disabled={isDisabled}
          />
        </SubHeader>
      </FormRow>
      {isNotification ? (
        <StyledContainer className={className}>
          <FormRow>
            {getValues().schedule.hasOwnProperty('dates') ? (
              <StyledTimePicker
                control={control}
                name="push_notification.startDate"
                label="Notification Time"
                disabled={isDisabled}
                errors={errors}
                defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                withAmPm={withAmPm}
                validation={{
                  required: ValidationMessages.RequiredField,
                }}
              />
            ) : (
              <StyledDateTimePicker
                control={control}
                name="push_notification.startDate"
                label="Notification Date"
                disabled={isDisabled}
                errors={errors}
                timezone={startTimezone}
                defaultValue={campaignStart}
                withAmPm={withAmPm}
                  minDate={(status !== ApprovalStatus.Active) ? maxDate(getMarketDatetime(startTimezone),campaignStart) : campaignStart}
                maxDate={campaignEnd}
                validation={{
                  required: ValidationMessages.RequiredField,
                  validate: {
                    minDate: (notificationDate1: Date) => {
                      try {
                        const notification: any = new Date(notificationDate1);
                        notification.setSeconds(0);
                        notification.setMilliseconds(0);
                        const currDateTime = notification.getTime();
                        if (Number.isNaN(currDateTime)) {
                          return `Notification Date is not valid`;
                        }
                        if (mode !== FormMode.View && currDateTime < getMarketDatetime(startTimezone).getTime() && (status !== ApprovalStatus.Active)) {
                          return `Start time is in the past`;
                        }
                        const campaignStartDate = new Date(campaignStart);
                        const campaignEndDate = new Date(campaignEnd);
                        campaignStartDate.setSeconds(0);
                        campaignStartDate.setMilliseconds(0);
                        campaignEndDate.setSeconds(0);
                        campaignEndDate.setMilliseconds(0);
                        if (
                          !(currDateTime >= campaignStartDate.getTime() && currDateTime <= campaignEndDate.getTime())
                        ) {
                          return `Notification time must be between the campaign start and end time`;
                        }
                      } catch (error) {
                        return `Notification Date is not valid`;
                      }
                    },
                  },
                }}
              />
            )}
          </FormRow>
          <LanguageNavigationContainer data-automation-id="languages">
            {languages.map((lang: Language, index: number) => {
              return (
                <LanguageNavigationItem
                  key={`${index}_${lang}`}
                  isActive={selectedLanguage === lang}
                  onClick={() => setSelectedLanguage(lang)}
                  data-automation-id="language-item"
                >
                  {LanguageRecord[lang]}
                </LanguageNavigationItem>
              );
            })}
          </LanguageNavigationContainer>
          {languages.map((lang: Language, index: number) => {
            const relevantTranslation: NotificationTranslation = translationsMap && translationsMap[lang];

            return (
              <LanguageContentContainer
                key={`${index}_${lang}`}
                className={selectedLanguage === lang ? 'selected' : ''}
              >
                <NotificationLanguage
                  register={register}
                  name={`push_notification.push_notification_translation.${index}.language`}
                  label="Notification Title"
                  value={lang}
                  show={selectedLanguage === lang}
                />
                <NotificationTitleStyledTextField
                  register={register}
                  name={`push_notification.push_notification_translation.${index}.title`}
                  label="Notification Title"
                  value={relevantTranslation?.notificationTitle}
                  placeholder={isNotificationTitle ? '' : 'Enter'}
                  disabled={isDisabled}
                  validation={{
                    required:
                      (config.isSecondaryLanguageEnforced && lang === config.secondaryLanguage) ||
                      lang === config.primaryLanguage,
                    maxLength: {
                      value: 100000,
                      message: 'Up to 100K characters',
                    },
                    validate: (value: string) => {
                      if (lang === config.primaryLanguage) {
                        return !hasValue(value) ? ValidationMessages.RequiredField : true;
                      }
                      if (config.isSecondaryLanguageEnforced) {
                        return !hasValue(value) ? ValidationMessages.RequiredField : true;
                      }
                      return !value || (!hasValue(value) ? ValidationMessages.RequiredField : true);
                    },
                  }}
                  errors={errors}
                  labelIsHorizontal
                  show={selectedLanguage === lang}
                />

                <StyledTextArea
                  register={register}
                  name={`push_notification.push_notification_translation.${index}.message`}
                  placeholder="Enter"
                  value={relevantTranslation?.notificationMessage}
                  label="Notification Message"
                  disabled={isDisabled}
                  validation={{
                    required:
                      (config.isSecondaryLanguageEnforced && lang === config.secondaryLanguage) ||
                      lang === config.primaryLanguage,
                    maxLength: {
                      value: 100000,
                      message: 'Up to 100K characters',
                    },
                    validate: (value: string) => {
                      if (lang === config.primaryLanguage) {
                        return !hasValue(value) ? ValidationMessages.RequiredField : true;
                      }
                      if (config.isSecondaryLanguageEnforced) {
                        return !hasValue(value) ? ValidationMessages.RequiredField : true;
                      }
                      return !value || (!hasValue(value) ? ValidationMessages.RequiredField : true);
                    },
                  }}
                  errors={errors}
                  rows={1}
                  labelIsHorizontal
                  show={selectedLanguage === lang}
                />
              </LanguageContentContainer>
            );
          })}
        </StyledContainer>
      ) : (
        ''
      )}
    </>
  );
};

export default PushNotificationSection;
