import { gql } from '@apollo/client';

export const offerBanksGqls = {
  queries: {
    getAll: gql`
      query GetOfferBanks($data: GetAllQueryDto) {
        getOfferBanks(data: $data) {
          items {
            ... on OfferBank {
              offerBankId
            }
          }
          total
        }
      }
    `,
  },
};
