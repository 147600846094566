import { gql } from '@apollo/client';

export const baseOfferRecsGqls = {
  queries: {
    getAll: gql`
      query GetBaseOfferRecs($data: GetAllQueryDto) {
        getBaseOfferRecs(data: $data) {
          items {
            ... on BaseOfferRec {
              id
              offerGroupId
            }
          }
          total
        }
      }
    `,
  },
};
