import { useLazyQuery } from '@apollo/client';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { offerBanksGqls } from 'pages/campaigns/doeCampaigns/offer.bank.gqls';
import { useEffect } from 'react';
import { OrderDirection } from 'utils/types';
import { FetchPolicies } from 'utils/types/common';

export const useOfferBanksQuery = (showErrorToast: true, filters?: any, order: any = { name: OrderDirection.ASC }) => {
  const [load, { data, loading, error, fetchMore }] = useLazyQuery(offerBanksGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters,
        order,
        offset: 0,
        limit: 50,
      },
    },
  });

  const items = data?.getOfferBanks?.items || [];
  const total = data?.getOfferBanks?.total || [];

  useEffect(() => {
    if (error && showErrorToast) {
      showToast(MessageType.Error, 'Error loading offer banks');
    }
  }, [error, showErrorToast]);

  const loadMore = () => {
    if (items && items.length < total) {
      fetchMore({
        variables: {
          data: {
            filters,
            order,
            offset: items.length,
            limit: 50,
          },
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult || !fetchMoreResult.getOfferBanks) {
            return previousResult; // If no new data, return the previous result
          }

          // Merge new items with existing items
          return {
            ...previousResult,
            getOfferBanks: {
              ...previousResult.getOfferBanks,
              items: [
                ...previousResult.getOfferBanks.items, // Existing items
                ...fetchMoreResult.getOfferBanks.items, // New fetched items
              ],
              total: fetchMoreResult.getOfferBanks.total,
              __typename: previousResult.getOfferBanks.__typename,
            },
          };
        },
      });
    }
  };

  return { load, items, loadMore };
};
