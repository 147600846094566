import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { DiscountType } from 'utils/types/offers';
import { DISCOUNT_VALUE } from '../DiscountComponent/DiscountComponent.consts';

export default function useDoeDiscountType() {
  const { trigger, getValues, watch, setValue, unregister, register } = useFormContext();
  const [discountType] = watch(['image.metadata.discount.discountType']);
  const isFree = discountType === DiscountType.Free;
  const isPercent = discountType === DiscountType.Percent;
  const shouldDisplayCurrency = discountType === DiscountType.FixedPrice || discountType === DiscountType.MoneyOff;

  useEffect(() => {
    if (!discountType) {
      setValue(DISCOUNT_VALUE, '', { shouldDirty: true });
      unregister(DISCOUNT_VALUE);
      register(DISCOUNT_VALUE);
      trigger(DISCOUNT_VALUE);
    } else {
      const val = getValues(DISCOUNT_VALUE);
      if (val !== '') {
        trigger(DISCOUNT_VALUE);
      }
    }
  }, [discountType]);

  return { discountType, isFree, isPercent, shouldDisplayCurrency };
}
