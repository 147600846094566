import React, { useEffect, useState } from 'react';
import {
  ActionButton,
  Container,
  FiltersRow,
  FlexWrapper,
  Footer,
  InfoIcon,
  NoOffers,
  OffersContainer,
  OffersList,
  Section,
  SectionContainer,
  Sections,
  SectionTitle,
  SelectedOffersList,
  StyledModal,
  StyledSearchTextField,
  StyledSelect,
  ZoneMsg,
} from 'pages/offers/offerManagement/components/zonesManagementModal/ZonesManagementModal.style';
import {
  ApprovalStatus,
  ApprovalStatusLabel,
  ApprovalStatusWithLabel,
  FormMode,
  GenericEntity,
  OrderDirection,
} from 'utils/types';
import { LocationSet } from 'utils/types/locations';
import locationSetsGqls from 'pages/settings/locationSets/LocationSets.gqls';
import { LocationSetsFilters } from 'pages/settings/locationSets/LocationSets.consts';
import { useQuery } from '@apollo/client';
import { ButtonContained, ButtonText } from 'components/shared/button';
import { store } from 'app/store';
import { closeModal, Modals, openModal } from 'app/slices/modals';
import { zoneOffersSelection } from 'app/genericSlices/offers';
import { useSelector } from 'react-redux';
import { OfferFilters, OfferSource } from 'pages/offers/offerManagement/Offers.const';
import offersGqls from 'pages/offers/offerManagement/Offers.gqls';
import { useToastError } from 'hooks/use-toast-error';
import { BaseOffer, OfferTemplates } from 'utils/types/offers';
import { useTagsQuery } from 'hooks/use-tags-query';
import { TagsValidEntities } from 'utils/types/tags';
import { StyledLoader } from 'pages/shared/shared.style';
import { LoaderSize } from 'components/shared/loader/Loader.consts';
import OfferItem from 'pages/offers/offerManagement/components/offerItem/OfferItem';
import { marketConfig } from 'app/slices/config';
import { buildObjectById, convertStringArrayToBooleanTrueRecord } from 'utils/mapping';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { hideTooltip } from 'utils/tooltip';
import ReactTooltip from 'react-tooltip';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { Selectbox } from 'components/shared/selectbox/Selectbox';
import { Feature } from 'utils/types/features';
import { FeatureGuard } from 'components/featureGuard/FeatureGuard';
import { updateZonesOffers } from 'utils/api/offers';
import { FetchPolicies } from 'utils/types/common';

export const ZonesManagementModal = ({ zones = [], selectOffers = [] }: { zones: any[]; selectOffers: any[] }) => {
  const { config } = useSelector(marketConfig);
  const [selectedZones, setSelectedZones] = useState(zones);
  const [filteredAvailableOffers, setFilteredAvailableOffers] = useState([]);
  const [selectedOffers, setSelectedOffers] = useState<Record<number, BaseOffer>>({});
  const [viewSelectedOffers, setViewSelectedOffers] = useState(selectOffers);
  const [filteredSelectedOffers, setFilteredSelectedOffers] = useState([]);
  const [removedOffers, setRemovedOffers] = useState<Record<number, boolean>>({});
  const { load: loadTags, tags } = useTagsQuery([TagsValidEntities.Offer]);
  const { filters: availableOffersFilters } = useSelector(zoneOffersSelection.offersState);
  const availableStatuses = [ApprovalStatus.Approved, ApprovalStatus.PendingApproval, ApprovalStatus.Draft];
  const { data: zonesData } = useQuery<{ getLocationSets: GenericEntity<LocationSet> }>(
    locationSetsGqls.queries.getZones,
    {
      fetchPolicy: FetchPolicies.CacheAndNetwork,
      nextFetchPolicy: FetchPolicies.CacheAndNetwork,
      notifyOnNetworkStatusChange: true,
      variables: {
        data: {
          filters: { [LocationSetsFilters.CustomSets]: false },
          order: { name: OrderDirection.ASC },
        },
      },
    },
  );

  const getOfferFilters = () => {
    // TODO - to show Available Offers amount we need to filter each time from server
    // Object.keys(selectedOffers).length && filters.AND.push({ id: { not_in: Object.keys(selectedOffers) } });
    return zoneOffersSelection.getFilters({
      [OfferFilters.TemplateType]: {},
      [OfferFilters.Tags]: {},
      [OfferFilters.SearchQuery]: '',
      ...availableOffersFilters,
      [OfferFilters.Status]:
        availableOffersFilters[OfferFilters.Status]?.length === 0
          ? availableStatuses
          : availableOffersFilters[OfferFilters.Status],
    });
  };

  const {
    data: offersData,
    error,
    fetchMore,
  } = useQuery(offersGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: getOfferFilters(),
        order: { id: OrderDirection.DESC },
        limit: 18,
        offerSource: OfferSource.VCE,
      },
    },
  });

  const {
    getOffers: { items: offers, total: offersTotal },
  } = offersData || { getOffers: {} };
  useToastError(error, 'Error loading offers');

  const { data: intersectionOffersData, error: OffersIntersectionError } = useQuery(
    offersGqls.queries.getOffersIntersection,
    {
      fetchPolicy: FetchPolicies.CacheAndNetwork,
      nextFetchPolicy: FetchPolicies.CacheAndNetwork,
      notifyOnNetworkStatusChange: true,
      skip: selectedZones.length === 0,
      variables: {
        data: {
          filters: zoneOffersSelection.getFilters({
            [OfferFilters.Zone]: selectedZones,
          }),
          order: { id: OrderDirection.DESC },
          offerSource: OfferSource.VCE,
        },
      },
    },
  );

  const {
    getOffersIntersection: { items: selectedOffersIntersection },
  } = intersectionOffersData || { getOffersIntersection: {} };
  useToastError(OffersIntersectionError, 'Error loading offers by selected zones');

  const fetchNextOffers = async () => {
    if (offers.length < offersTotal) {
      await fetchMore({
        variables: {
          data: {
            filters: zoneOffersSelection.getFilters({
              [OfferFilters.TemplateType]: {},
              [OfferFilters.Tags]: {},
              [OfferFilters.SearchQuery]: '',
              ...availableOffersFilters,
              [OfferFilters.Status]:
                availableOffersFilters[OfferFilters.Status]?.length === 0
                  ? availableStatuses
                  : availableOffersFilters[OfferFilters.Status],
            }),
            offset: offers.length,
            limit: 18,
            order: { id: OrderDirection.DESC },
            offerSource: OfferSource.VCE,
          },
        },
      });
    }
  };

  const filterSelected = (searchValue: string) => {
    if (searchValue) {
      setFilteredSelectedOffers(
        Object.values(selectedOffers).filter(
          (offer) =>
            offer.versions[0]?.translationsMap[config.primaryLanguage]?.title
              ?.toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            offer.versions[0]?.translationsMap[config.primaryLanguage]?.posTitle
              ?.toLowerCase()
              .includes(searchValue.toLowerCase()),
        ),
      );
    } else {
      setFilteredSelectedOffers(Object.values(selectedOffers));
    }
  };

  const addOffer = (offer: BaseOffer) => {
    setSelectedOffers({ ...selectedOffers, [offer.id]: offer });
    if (removedOffers[offer.id]) {
      const newRemovedOffers = { ...removedOffers };
      delete newRemovedOffers[offer.id];
      setRemovedOffers({
        ...newRemovedOffers,
      });
    }
  };

  const removeOffer = (offerId: number) => {
    const newSelectedOffers = { ...selectedOffers };
    delete newSelectedOffers[offerId];
    setSelectedOffers({
      ...newSelectedOffers,
    });
    setRemovedOffers({ ...removedOffers, [offerId]: true });
  };

  const removeAll = () => {
    const toRemove = convertStringArrayToBooleanTrueRecord(Object.keys(selectedOffers));
    setRemovedOffers({ ...removedOffers, ...toRemove });
    setSelectedOffers({});
    setViewSelectedOffers([]);
  };

  const resetOffersFilters = () => {
    store.dispatch(
      zoneOffersSelection.actions.setFilters({
        [OfferFilters.Status]: [ApprovalStatus.Approved],
        [OfferFilters.TemplateType]: {},
        [OfferFilters.Tags]: {},
        [OfferFilters.SearchQuery]: '',
      }),
    );
  };

  const save = async () => {
    try {
      const data = {
        locationSetIds: selectedZones.map(Number),
        offersToAdd: Object.keys(selectedOffers).map(String),
        offersToRemove: Object.keys(removedOffers).map(String),
      };
      await updateZonesOffers(data);
      resetOffersFilters();
      store.dispatch(closeModal());
      showToast(MessageType.Success, 'Zone Offers successfully updated.');
    } catch (e) {
      showToast(MessageType.Error, 'Zone Offers failed to update.');
    }
  };

  useEffect(() => {
    if (offers?.length && selectedOffers) {
      setFilteredAvailableOffers(offers.filter((offer: BaseOffer) => !selectedOffers[offer.id]));
    }
  }, [offers, selectedOffers]);

  useEffect(() => {
    if (selectedOffersIntersection) {
      setSelectedOffers(buildObjectById(selectedOffersIntersection));
    }
  }, [selectedOffersIntersection]);

  useEffect(() => {
    if (selectedOffers) {
      setFilteredSelectedOffers(Object.values(selectedOffers));
    }
    ReactTooltip.rebuild();
  }, [selectedOffers]);

  useEffect(() => {
    if (viewSelectedOffers) {
      setSelectedOffers((prevOffers) => {
        const mergedOffers = {
          ...prevOffers,
          ...Object.fromEntries(viewSelectedOffers.map((offer) => [offer.id, offer])),
        };
        return mergedOffers;
      });
    }
  }, [viewSelectedOffers]);

  useEffect(() => {
    if (!selectedZones.length) {
      setSelectedOffers({});
      setViewSelectedOffers([]);
    }
  }, [selectedZones]);

  useEffect(() => {
    loadTags();
    if (!zones.length) {
      resetOffersFilters();
    }
  }, []);

  return (
    <StyledModal title="Zone Offer Management" withLoader={false}>
      <Container>
        <FlexWrapper>
          <Selectbox
            placeholder="Select"
            name="zone"
            label="Zone"
            items={zonesData?.getLocationSets?.items}
            onChange={(selectedItems: any) => {
              setSelectedZones(selectedItems.map((item: any) => item.id));
              setViewSelectedOffers([]);
            }}
            reset
            withSearch
            multiple
            maxItems={2}
            selectWidth={552}
            {...(zones.length ? { initialSelectedItems: zones } : {})}
          />
          {selectedZones.length > 1 && (
            <>
              <FlexWrapper data-for="zones-info-tooltip" data-tip>
                <InfoIcon name="info" />
              </FlexWrapper>
              <Tooltip
                id="zones-info-tooltip"
                content="Selected Offers section displays Offers that are assigned in all zones filtered."
                onHover
                place="bottom"
              />
            </>
          )}
        </FlexWrapper>
        <Sections>
          <Section>
            <SectionTitle>Available Offers </SectionTitle>
            <SectionContainer>
              <StyledSearchTextField
                name="availableOffersSearch"
                onChange={(e) => {
                  store.dispatch(
                    zoneOffersSelection.actions.setFilter({
                      filter: [OfferFilters.SearchQuery],
                      value: e.target.value !== '' ? [e.target.value] : [],
                    }),
                  );
                }}
                disabled={!selectedZones.length}
                value={availableOffersFilters[OfferFilters.SearchQuery]}
              />
              <FiltersRow>
                <FeatureGuard features={[{ feature: Feature.DraftCampaignOfferEnhancement }]}>
                  <StyledSelect
                    placeholder="Select"
                    label="Status"
                    name="offer-status"
                    multiple
                    items={
                      availableStatuses.map((status) => {
                        return {
                          id: status,
                          name: ApprovalStatusLabel[status as ApprovalStatusWithLabel],
                        };
                      }) as any[]
                    }
                    onChange={(selectedItems) =>
                      store.dispatch(
                        zoneOffersSelection.actions.setFilter({
                          filter: [OfferFilters.Status],
                          value: Object.values(selectedItems).map((i: any) => i.id),
                        }),
                      )
                    }
                    initialSelectedItems={availableOffersFilters[OfferFilters.Status]}
                    reset
                    maxItems={1}
                    selectWidth={140}
                  />
                </FeatureGuard>
                <StyledSelect
                  name="template"
                  placeholder="Select"
                  label="Template"
                  multiple
                  items={Object.values(OfferTemplates) as any[]}
                  onChange={(selectedItems) =>
                    store.dispatch(
                      zoneOffersSelection.actions.setFilter({
                        filter: [OfferFilters.TemplateType],
                        value: Object.values(selectedItems).map((i: any) => i.id),
                      }),
                    )
                  }
                  withSearch
                  reset
                  maxItems={1}
                  disabled={!selectedZones.length}
                  selectWidth={130}
                  {...(zones.length && availableOffersFilters[OfferFilters.TemplateType].length
                    ? { initialSelectedItems: availableOffersFilters[OfferFilters.TemplateType] }
                    : {})}
                />
                <StyledSelect
                  name="tags"
                  placeholder="Select"
                  label="Tags"
                  multiple
                  items={tags}
                  onChange={(selectedItems) =>
                    store.dispatch(
                      zoneOffersSelection.actions.setFilter({
                        filter: [OfferFilters.Tags],
                        value: Object.values(selectedItems).map((i: any) => i.id),
                      }),
                    )
                  }
                  withSearch
                  reset
                  maxItems={1}
                  disabled={!selectedZones.length}
                  selectWidth={130}
                  {...(zones.length && availableOffersFilters[OfferFilters.Tags].length
                    ? { initialSelectedItems: availableOffersFilters[OfferFilters.Tags] }
                    : {})}
                />
                {/*
                                <ActionButton onClick={selectAll} disabled={!selectedZones.length || !filteredAvailableOffers.length}>
                  Select All
                </ActionButton>
                */}
              </FiltersRow>
              {selectedZones?.length ? (
                <OffersContainer id="available-offers-scroll">
                  {!offers ? (
                    <StyledLoader size={LoaderSize.Large} />
                  ) : (
                    <>
                      {offersTotal === 0 ? (
                        <NoOffers>No Approved Offers</NoOffers>
                      ) : (
                        <OffersList
                          scrollableTarget="available-offers-scroll"
                          dataLength={offers.length}
                          next={fetchNextOffers}
                          hasMore={offers.length < offersTotal}
                          loader={<StyledLoader size={LoaderSize.Small} />}
                        >
                          {filteredAvailableOffers?.map((offer: BaseOffer) => (
                            <OfferItem
                              key={offer.id}
                              offer={offer}
                              offerSource={OfferSource.VCE}
                              onClick={() => null}
                              isSelectMode
                              isSelected={false}
                              onAddClick={() => addOffer(offer)}
                              onRemoveClick={() => removeOffer(offer.id)}
                              onViewClick={() =>
                                store.dispatch(
                                  openModal({
                                    modal: Modals.OfferModal,
                                    props: {
                                      viewOnly: true,
                                      offer,
                                      onClose: () =>
                                        store.dispatch(
                                          openModal({
                                            modal: Modals.ZonesManagementModal,
                                            props: {
                                              zones: selectedZones,
                                              selectOffers: Object.values(selectedOffers),
                                            },
                                          }),
                                        ),
                                    },
                                    data: { mode: FormMode.View },
                                  }),
                                )
                              }
                            />
                          ))}
                        </OffersList>
                      )}
                    </>
                  )}
                </OffersContainer>
              ) : (
                <ZoneMsg>Please select zone to display available Offers</ZoneMsg>
              )}
            </SectionContainer>
          </Section>
          <Section>
            <SectionTitle>Selected Offers ({Object.keys(selectedOffers).length})</SectionTitle>
            <SectionContainer>
              <StyledSearchTextField
                name="selectedOffersSearch"
                onChange={(e) => filterSelected(e.target.value)}
                disabled={!selectedZones.length}
              />
              <FiltersRow>
                <ActionButton onClick={removeAll} disabled={!Object.keys(selectedOffers).length}>
                  Remove All
                </ActionButton>
              </FiltersRow>
              {Boolean(selectedZones?.length) && (
                <SelectedOffersList>
                  {filteredSelectedOffers?.map((offer: BaseOffer) => (
                    <OfferItem
                      key={offer.id}
                      offer={offer}
                      onClick={() => null}
                      isSelectMode
                      isSelected
                      onAddClick={() => addOffer(offer)}
                      onRemoveClick={() => removeOffer(offer.id)}
                      onViewClick={() =>
                        store.dispatch(
                          openModal({
                            modal: Modals.OfferModal,
                            props: {
                              offer,
                              viewOnly: true,
                              onClose: () =>
                                store.dispatch(
                                  openModal({
                                    modal: Modals.ZonesManagementModal,
                                    props: {
                                      zones: selectedZones,
                                      selectOffers: Object.values(selectedOffers),
                                    },
                                  }),
                                ),
                            },
                            data: { mode: FormMode.View },
                          }),
                        )
                      }
                    />
                  ))}
                </SelectedOffersList>
              )}
            </SectionContainer>
          </Section>
        </Sections>
        <Footer>
          <ButtonText
            data-for="cancel-tooltip"
            {...(Object.keys(selectedOffers) || Object.keys(removedOffers)
              ? { 'data-tip': true, onClick: () => null }
              : {
                  onClick: () => {
                    resetOffersFilters();
                    store.dispatch(closeModal());
                  },
                })}
          >
            Cancel
          </ButtonText>
          <ButtonContained disabled={false} onClick={save}>
            Save
          </ButtonContained>
          <Tooltip
            id="cancel-tooltip"
            content="Are you sure you want to cancel?"
            onDisapproveClick={() => {
              hideTooltip('#cancel-tooltip');
            }}
            onApproveClick={() => {
              resetOffersFilters();
              store.dispatch(closeModal());
            }}
          />
        </Footer>
      </Container>
    </StyledModal>
  );
};
