import { ApprovalStatus, GenericSet, OrderDirection } from 'utils/types';
import { Language } from 'utils/languages';
import { BaseOffer } from 'utils/types/offers';
import { ProductSet } from 'utils/types/products';
import { ImageProps } from '../../settings/images/Images.consts';

export enum OfferSource {
  VCE = 'VCE',
  DOE = 'DOE',
}

export interface OfferTranslation {
  title: string;
  posTitle?: string;
  subtitle?: string;
  description?: string;
  language?: Language;
  image?: ImageProps;
}

export interface OfferApproval {
  id: number;
  approvalStatus: ApprovalStatus;
  comment?: string;
}

export interface Term {
  id: number;
  name: string;
  originalTermId?: string;
}

export interface OfferVersion {
  id?: number;
  templateValues?: { [key: string]: any };
  approvals?: OfferApproval[];
  term: Term;
  translations: OfferTranslation[];
  isDraft: boolean;
  status: ApprovalStatus;
}

export interface OfferProps {
  id: number;
  versions?: OfferVersion[];
  tags?: number[];
  templateType?: string;
  isArchive?: boolean;
  isLocked?: boolean;
}

export interface UpdateOfferProps {
  id: string | number;
  name?: string;
  version?: OfferVersion;
  tags?: number[];
  templateType?: string;
  isArchive?: boolean;
  term?: Term;
}

export enum OfferFilters {
  TemplateType = 'templateType',
  Status = 'status',
  Tags = 'tags',
  SearchQuery = 'searchQuery',
  Id = 'id',
  Zone = 'zone',
  OfferGroup = 'offerGroup',
  OfferBank = 'offerBank',
}

export interface OffersPageState {
  filters: {
    [key in OfferFilters]?: any[];
  };
  order?: {
    [key: string]: OrderDirection;
  };
  offersForm?: {
    show?: boolean;
    offer?: BaseOffer;
    productsSelection?: {
      show: boolean;
      sets: ProductSet[];
      products: GenericSet[];
      key: string;
    };
  };
  offerImpact?: {
    show?: boolean;
    offer?: any;
  };
}
