import { OfferProps } from '../pages/offers/offerManagement/Offers.const';
import { getDoeImageById } from './api/images';
import { DoeImage } from './types/images';
import { BaseOffer } from './types/offers';

export const getOfferStatus = (offer: BaseOffer | OfferProps) => {
  if (!offer?.versions || !offer?.versions[0]?.status) {
    return null;
  }
  return offer.versions[0].status;
};

export const handleOfferImpactChange = (offers: OfferProps[]) => {
  return offers?.map((offer: OfferProps) => ({
    id: offer?.id,
    entityId: offer?.id,
    title: offer?.versions[0]?.translations[0]?.title,
    status: getOfferStatus(offer),
    isDefault: !offer?.versions[0]?.term?.originalTermId,
  }));
};

export const viewImageClicked = async (id: number) => {
  const { data: doeImageData } = await getDoeImageById(Number(id));
  const imageData: DoeImage = { ...doeImageData.getDoeImage } as any;
  return imageData;
};
