import { ButtonContained } from 'components/shared/button';
import { ChangesImpactProps, HeaderComponentType, ModalHeaderComponentMap } from './ImpactModal.consts';
import { StyledButtonOutlined, StyledButtonsContainer, StyledContentContainer, StyledModal } from './ImpactModal.style';
import { useQuery } from '@apollo/client';
import { campaignsGqls } from 'pages/campaigns/campaignManagement/Campaigns.gqls';
import { useSelector } from 'react-redux';
import { getCampaignForProductChangeDetails, getCampaignImpactsByIds } from 'utils/api/campaigns';
import { useToastError } from 'hooks/use-toast-error';
import { downloadCSV } from 'utils/download';
import { ImpactType } from '../impactItem/ImpactItem.consts';
import { StyledImpactItem, StyledImpactTables, StyledImpactTablesContainer } from '../impactTables/ImpactsTables.style';
import { CampaignApprovalStatuses } from 'utils/types/campaigns';
import offersGqls from 'pages/offers/offerManagement/Offers.gqls';
import { getOfferForProductChangeDetails, getOfferImpactsByIds } from 'utils/api/offers';
import { OfferApprovalStatuses } from 'utils/types/offers';
import { campaignImpact as campaignImpactSlice, offerImpact as offerImpactSlice } from 'app/genericSlices/impacts';
import { getColumns } from '../impactTables/ImpactTablesColumns';
import { getUsageReportRows } from 'utils/impact';
import { OrderDirection } from 'utils/types';
import { marketConfig } from 'app/slices/config';
import { FetchPolicies } from 'utils/types/common';

const AuditImpactModal = ({
  campaignIds = [],
  offerIds = [],
  onCancel,
  entityId,
  showOffers = false,
  title,
}: ChangesImpactProps) => {
  const { filters: campaignFliters } = useSelector(campaignImpactSlice.impactsState);
  const { filters: offerFliters } = useSelector(offerImpactSlice.impactsState);
  const { config } = useSelector(marketConfig);

  const {
    data: campaignData,
    error: campaignError,
    fetchMore: campaignFetchMore,
  } = useQuery(campaignsGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    skip: !campaignIds.length,
    variables: {
      data: {
        filters: campaignImpactSlice.getFilters({ ...campaignFliters, id: campaignIds }),
        order: {
          externalId: OrderDirection.DESC,
        },
        limit: 25,
      },
    },
  });
  const {
    getCampaigns: { items: campaigns, total: totalCamapigns },
  } = campaignData || { getCampaigns: { items: [], total: 0 } };
  const campaignImpacts = getCampaignForProductChangeDetails(campaigns);
  useToastError(campaignError, 'Error loading affected campaigns');

  const fetchNextCampaignsChanges = async () => {
    if (campaigns.length < totalCamapigns) {
      await campaignFetchMore({
        variables: {
          data: {
            filters: campaignImpactSlice.getFilters({ ...campaignFliters, id: campaignIds }),
            offset: campaigns.length,
            order: {
              externalId: OrderDirection.DESC,
            },
            limit: 25,
          },
        },
      });
    }
  };

  const {
    data: offerData,
    error: offerError,
    fetchMore: offerFetchMore,
  } = useQuery(offersGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    skip: !(showOffers && offerIds.length),
    variables: {
      data: {
        filters: offerImpactSlice.getFilters({ ...offerFliters, id: offerIds }),
        order: {
          id: OrderDirection.DESC,
        },
        limit: 25,
      },
    },
  });
  const {
    getOffers: { items: offers, total: totalOffers },
  } = offerData || { getOffers: { items: [], total: 0 } };
  const offerImpacts = getOfferForProductChangeDetails(offers, config);
  useToastError(offerError, 'Error loading affected offers');

  const fetchNextOffersChanges = async () => {
    if (offers.length < totalOffers) {
      await offerFetchMore({
        variables: {
          data: {
            filters: offerImpactSlice.getFilters({ ...offerFliters, id: offerIds }),
            order: {
              id: OrderDirection.DESC,
            },
            offset: offers.length,
            limit: 25,
          },
        },
      });
    }
  };

  const downloadImpactReport = async () => {
    const [allImpactedCampaigns, allImpactedOffers] = await Promise.all([
      campaignIds?.length ? getCampaignImpactsByIds({ ...campaignFliters, id: campaignIds }) : Promise.resolve([]),
      offerIds?.length
        ? getOfferImpactsByIds(offerImpactSlice.getFilters({ ...offerFliters, id: offerIds }), config)
        : Promise.resolve([]),
    ]);
    const rows = getUsageReportRows(allImpactedCampaigns, allImpactedOffers);
    downloadCSV(rows, `UsageSummary_${showOffers ? 'Product' : 'Location'}_ID#${entityId}`);
  };

  const ModalHeaderComponent = showOffers
    ? ModalHeaderComponentMap[HeaderComponentType.ProductUsageType]
    : ModalHeaderComponentMap[HeaderComponentType.LocationUsageType];

  return (
    <StyledModal title={title}>
      <StyledContentContainer data-automation-id="impact">
        <ModalHeaderComponent />
        <StyledImpactTablesContainer>
          <StyledImpactTables>
            <StyledImpactItem
              impactType={ImpactType.Campaign}
              impactStatuses={CampaignApprovalStatuses}
              impacts={campaignImpacts}
              totalLength={totalCamapigns}
              onRowsScrollEnd={fetchNextCampaignsChanges}
              tableProps={{
                columns: getColumns(ImpactType.Campaign),
                rows: campaignImpacts,
              }}
              impactSlice={campaignImpactSlice}
            />
            {Boolean(showOffers) && (
              <StyledImpactItem
                impactType={ImpactType.Offer}
                impactStatuses={OfferApprovalStatuses}
                impacts={offerImpacts}
                totalLength={totalOffers}
                onRowsScrollEnd={fetchNextOffersChanges}
                tableProps={{
                  columns: getColumns(ImpactType.Offer),
                  rows: offerImpacts,
                }}
                impactSlice={offerImpactSlice}
              />
            )}
          </StyledImpactTables>
        </StyledImpactTablesContainer>
        <StyledButtonsContainer data-automation-id="actions">
          <StyledButtonOutlined onClick={() => downloadImpactReport()}>Download Usage Report</StyledButtonOutlined>
          <ButtonContained onClick={onCancel}>{'Close'}</ButtonContained>
        </StyledButtonsContainer>
      </StyledContentContainer>
    </StyledModal>
  );
};

export default AuditImpactModal;
