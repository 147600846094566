import { appTheme } from 'styles/themes/defaultTheme';
import { FormMode } from 'utils/types';
import { DoeImage } from 'utils/types/images';
export interface DoeImageFormProps {
  mode?: FormMode;
  onClose: (refresh?: boolean) => void;
  image?: DoeImage;
  className?: string;
  viewOnly?: boolean;
}

export interface ImageFormContainerProps {
  theme?: appTheme;
}

export const DEFAULT_TEMPLATES = 'image.defaultTemplates';
export const METADATA = 'image.metadata';
export const DISCOUNT_COMPONENT_CHECKBOX = 'image.discountComponentCheckbox';
export const SPECIAL_OPTIONS = 'image.metadata.specialOptions';

export interface MetadataFieldsValidation {
  discount: boolean;
  products: boolean;
  specialOptions: boolean;
}
export type SetMetadataFieldsValidation = React.Dispatch<React.SetStateAction<MetadataFieldsValidation>>;
