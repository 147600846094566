import styled from '@emotion/styled';
import Modal from 'components/shared/modal/Modal';
import { Item } from 'pages/shared/setItemsSelectionForm/listItem/ListItem.style';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';

export const StyledSegmentIdModal = styled(Modal)`
  width: 564px;
  min-width: 564px;
  height: 85vh;
  max-height: 790px;
  header h3 {
    margin-bottom: 12px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-top: ${typography.unit * 6}px;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const StyledListContainer = styled.div<StyledWithThemeProps>`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border: 1px solid ${({ theme }) => theme.colors.global.border};
  border-radius: ${typography.borderRadius}px;
`;
export const StyledListItem = styled(Item)`
  padding: 0 !important;
  margin-left: ${typography.unit * 5}px;
`;
