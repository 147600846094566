import { User } from './types/users';

const postgresMaxInteger = 2 ** 31 - 1;

export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeArrayElements = (str: string[]): string[] => {
  return str.map(s => s.charAt(0).toUpperCase() + s.slice(1));
};

export const pluralize = (array: any, suffix = 's') => `${array.length !== 1 ? suffix : ''}`;

export const hasValue = (str: string) => str?.trim() !== '';

export const getAcronym = (user: User) =>
  user?.firstName && user.lastName ? `${user.firstName[0]}${user.lastName[0]}` : user.email[0];

export const isNumber = (text: string) => /^-?\d+[.]?\d*$/.test(`${text}`);

export const isPositiveInteger = (text: string) => Number(text) > 0 && Number(text) < postgresMaxInteger;
