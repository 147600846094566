import { OrderDirection } from 'utils/types';
import { Tag } from 'utils/types/tags';

export enum ImageSource {
  VCE = 'VCE',
  DOE = 'DOE',
}

export interface ImageMetadata {
  products?: {
    code: number[];
    count: number;
  }[];
  discount?: {
    discountType: string;
    value: number;
    minPurchase?: number;
  };
  specialOptions?: {
    isMop?: boolean;
    isApplePay?: boolean;
    isMcDeliveryFree?: boolean;
    isLTO?: boolean;
  };
}

export enum ImageFilterType {
  Archive = 'archive',
  Tags = 'tags',
  SearchQuery = 'searchQuery',
  Id = 'id',
  ImageSource = 'imageSource',
}

export const SortBy: { [key: string]: { [key: string]: OrderDirection } } = {
  'Last updated': {
    updatedAt: OrderDirection.DESC,
  },
  'A - Z': {
    name: OrderDirection.ASC,
  },
  'Z - A': {
    name: OrderDirection.DESC,
  },
};

export interface UploadedImageProps {
  name: string;
  file: File;
  src: string;
  fileExtension: string;
}

export interface ImageProps {
  id?: number;
  name: string;
  description?: string;
  file?: string;
  fileName?: string;
  src?: string;
  fileExtension?: string;
  tags?: Tag[];
  updatedAt?: string;
  isArchive?: boolean;
  isLocked?: boolean;
  isBonusCampaign?: boolean;
  metadata?: ImageMetadata;
  metadatHash?: string;
  defaultTemplates?: number[];
  selectedLanguages?: string[];
  imageSource: ImageSource;
}

export interface ImagePropsToSave {
  id?: number;
  name?: string;
  description?: string;
  file?: string;
  fileExtension?: string;
  tags?: (string | number)[];
  isArchive?: boolean;
  isBonusCampaign?: boolean;
}
