import { useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { DoeImage } from 'utils/types/images';

export const useDoeImageFromRedux = (image: DoeImage) => {
  const {
    image: persistentImage,
    mode: persistentMode,
    updatedImagePlu: persistentUpdatedImagePlu,
  }: { image: DoeImage; mode: string; updatedImagePlu: boolean } = useSelector((state: any) => {
    const data = cloneDeep({ ...state.modals.data });
    data.image = { ...image, ...data.image };
    data.updatedImagePlu = false;
    if (data.templateProducts) {
      data.updatedImagePlu = true;
      const index = Object.keys(data.templateProducts.products)[0];
      data.image.metadata.products[index].code = data.templateProducts.products[index];
    }
    return cloneDeep({ ...data });
  });

  return { image: persistentImage, mode: persistentMode, updatedImagePlu: persistentUpdatedImagePlu };
};
