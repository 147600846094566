import { FetchResult } from '@apollo/client';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';

export interface CampaignBulkActionsProps {
  bulkAction: CampaignBulkAction;
  offerSource: OfferSource;
  onBulkActionChange: (bulkAction: CampaignBulkAction) => void;
  onBulkActionSubmit: (action?: CampaignBulkAction) => Promise<FetchResult<BulkCampaignMutationResult>> | Promise<bigint[]>;
  selectedCampaigns: {
    total: number;
    local: number;
    conflicted: number;
    notEligibleForSelfApproval: number;
    invalid: number;
  };
  bulkUpdateInProgress: boolean;
}

export enum CampaignBulkAction {
  BulkSubmitForApproval = 'bulk submit for approval',
  BulkApprove = 'bulk approve',
  BulkStopAssociation = 'bulk stop association',
  BulkRevoke = 'bulk revoke',
}

export interface BulkCampaignMutationResult {
  bulkCampaignsStatusUpdate: {
    isProcessed: boolean;
  };
}
