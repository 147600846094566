import styled from '@emotion/styled';
import { ButtonDropdown } from 'components/shared/button/buttonDropdown';
import { StyledWithThemeProps } from 'utils/types';
import typography from "styles/typography";

export const StyledButtonDropdown = styled(ButtonDropdown)<StyledWithThemeProps>`
  height: 32px;
  width: 56px;
  padding: 0;
    
  &:only-child {
    margin-right: ${typography.unit * 4}px;
  }

  button {
    padding: 0 6px;
  }
`;
