import { store } from 'app/store';
import { closeModal } from 'app/slices/modals';
import { MessageType } from 'components/shared/notifications/notifications';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { createNewPeriod, updatePeriod } from 'utils/api/periods';
import { PeriodProps } from 'pages/settings/schedulePeriods/SchedulePeriods.consts';

export const createPeriod = async (period: PeriodProps) => {
  try {
    await createNewPeriod(period);
    store.dispatch(closeModal());
    showToast(MessageType.Success, `Period added successfully`);
  } catch (e) {
    let errorMessageSuffix = '';

    if (e.message.includes('duplication item')) {
      errorMessageSuffix = `${period.name} already exists`;
    } else if (e.message.includes('already exists with the same dates')) {
      errorMessageSuffix = ' - Period already exists';
    }

    showToast(MessageType.Error, `Failed to add period ${errorMessageSuffix}`);
  }
};

export const editPeriod = async (period: Partial<PeriodProps>) => {
  try {
    await updatePeriod(period);
    store.dispatch(closeModal());
    showToast(MessageType.Success, `Period updated successfully`);
  } catch (e) {
    let errorMessageSuffix = '';
    if (e.message.includes('duplication item')) {
      errorMessageSuffix = ` - ${period.name} already exists`;
    } else if (e.message.includes('already exists with the same dates')) {
      errorMessageSuffix = ' - Period already exists';
    }
    showToast(MessageType.Error, `Failed to update period ${errorMessageSuffix}`);
  }
};
