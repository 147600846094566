import React from 'react';
import { DiscountType } from 'utils/types/offers';
import { useFormContext } from 'react-hook-form';
import { calcProductsHint } from 'utils/product';
import useDiscountType from 'hooks/use-discount-type';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import { ProductPicker, StyledBuySelectbox, StyledForSelectbox, StyledLabel, StyledTextField } from '../Template.style';
import { DiscountTypeRecord, TemplateProps } from '../Template.consts';
import getProductsAction from '../shared/ProductsActionCondition';
import { Currency } from '../shared/Currency/Currency';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';

export const MultiItemDiscount = ({ disabled, onProductSelection, offerSource, mode }: TemplateProps) => {
  const {
    control,
    register,
    getValues,
    watch,
    formState: { errors },
    trigger,
    clearErrors,
  } = useFormContext();

  const { isPercent, shouldDisplayCurrency } = useDiscountType();
  const { config } = useSelector(marketConfig);

  const discountValueName = 'versions.0.templateValues.discountValue';

  const [buyProducts, discountType, buy, discountValue] = watch([
    'versions.0.templateValues.buyProducts',
    'versions.0.templateValues.discountType',
    'versions.0.templateValues.buy',
    discountValueName
  ]);


  const handleChange = () => {
    clearErrors(discountValueName);
    if (discountValue) {
      trigger(discountValueName);
    }
  }

  return (
    <>
      <div>
        <StyledLabel>Buy</StyledLabel>
        <StyledBuySelectbox
          control={control}
          name="versions.0.templateValues.buy"
          disabled={disabled || (offerSource === OfferSource.DOE && mode === 'edit')}
          items={Array.from(
            { length: 15 },
            (x, i) =>
              ({
                id: (i + 1).toString(),
                name: (i + 1).toString(),
              } as any),
          )}
          defaultValue="1"
          initialSelectedItems={buy ? [typeof buy === 'object' ? buy.id : buy] : ['1']}
          selectWidth={60}
          onChange={handleChange}
        />
        <ProductPicker>
          {calcProductsHint(buyProducts)}
          {getProductsAction(
            disabled,
            buyProducts,
            getValues,
            'Select Products',
            'buyProducts',
            (data: any) => ({
              buyProducts: data,
            }),
            onProductSelection,
          )}
          <input
            type="hidden"
            {...register('versions.0.templateValues.buyProducts', {
              value: buyProducts,
              shouldUnregister: true,
              required: true,
            })}
          />
        </ProductPicker>
      </div>
      <div>
        <StyledLabel>For</StyledLabel>
        <StyledForSelectbox
          name="versions.0.templateValues.discountType"
          control={control}
          disabled={disabled || (offerSource === OfferSource.DOE && mode === 'edit')}
          validation={{
            required: true,
          }}
          items={
            Object.entries(DiscountTypeRecord())
              .filter(([key]) => key !== DiscountType.Free)
              .map(([key, value]) => {
                return {
                  id: key,
                  name: value,
                };
              }) as any[]
          }
          selectWidth={100}
          defaultValue={DiscountType.MoneyOff}
          initialSelectedItems={discountType ? [discountType] : [DiscountType.MoneyOff]}
          onChange={handleChange}
        />
        {shouldDisplayCurrency && <Currency />}
        <StyledTextField
          disabled={disabled || (offerSource === OfferSource.DOE && mode === 'edit')}
          register={register}
          validation={{
            required: true,
            min: isPercent ? 1 : 0.01,
            max: isPercent ? 99 : null,
            pattern: isPercent ? /^([1-9][0-9]?|99)$/ : /^\d+(?:\.\d{1,2})?$/,
            validate: (v: string) => {
              const buy = watch('versions.0.templateValues.buy');
              const isNonExactPenny = Math.ceil(+(Number(v) * 100).toFixed(2)) % Number(buy) !== 0;
              if (discountType === DiscountType.MoneyOff && isNonExactPenny){
                return `${config.currency} off divided by number of items does not result in exact penny value for selected option`;
              }
              if (discountType === DiscountType.FixedPrice && isNonExactPenny){
                return 'Fixed Price total divided by number of items does not result in exact penny value for selected option';
              }
              return (
                (!Number.isNaN(Number(v)) && Number(v) >= 0.01) || (isPercent && Number(v) < 100 && Number(v) >= 1)
              );
            },
          }}
          errors={errors}
          name="versions.0.templateValues.discountValue"
          placeholder="Enter value"
          showError={false}
        />
      </div>
    </>
  );
};
