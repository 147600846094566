import styled from '@emotion/styled';
import { Icon, IconProps } from 'components/shared/icon';
import TextField from 'components/shared/textField/TextField';
import { ThemeProps } from 'pages/settings/termsConditions/TermsConditions.consts';
import typography from 'styles/typography';

export const SegmentIdContainer = styled.div<ThemeProps>`
  display: flex;
  flex-direction: row;
  padding-top: ${typography.unit * 2}px;
`;
export const SegmentIdContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${typography.unit * 1}px;
`;
export const StyledTextField = styled(TextField)`
  & > div > div {
    border-bottom: none;
  }
`;
export const StyledIcon = styled(Icon)<IconProps>`
  fill: ${({ theme }) => theme.colors.global.inputPrimary};
  margin-right: ${typography.unit * 2}px;
  cursor: pointer;
  width: 18px;
`;
