import { useSelector } from 'react-redux';
import { BaseOffer } from 'utils/types/offers';
import cloneDeep from 'lodash/cloneDeep';

export const useOfferFromRedux = (offer: BaseOffer) => {
  const {
    offer: persistentOffer,
    mode: persistentMode,
    selectedLanguage: persistentLanguage,
    offerSource: persistentOfferSource,
  }: { offer: BaseOffer; mode: string; selectedLanguage: string; offerSource: string } = useSelector((state: any) => {
    const data = cloneDeep({ ...state.modals.data });
    if(data?.offer?.versions[0]?.term){
      data.offer.versions[0].term = Number(data.offer.versions[0].term);
    }
    data.offer = { ...data.offer, ...offer };
    if (data.image) {
      data.offer.versions[0].translationsMap[data.selectedLanguage].image = data.image;
    }

    if (data.templateProducts) {
      if (!data.templateProducts.productCombo) {
        if (data.offer.versions) {
          data.offer.versions[0].templateValues = {
            ...data.offer.versions[0].templateValues,
            ...data.templateProducts,
          };
        }
      } else {
        const index = Object.keys(data.templateProducts.productCombo)[0];
        data.offer.versions[0].templateValues.offerTemplates[index].buyProducts =
          data.templateProducts.productCombo[index];
      }
    }

    return cloneDeep({ ...data });
  });

  return { offer: persistentOffer, mode: persistentMode, selectedLanguage: persistentLanguage, offerSource: persistentOfferSource };
};
