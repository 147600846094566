import React, { useEffect } from 'react';
import TextPageTitle from 'components/shared/text/textPageTitle/TextPageTitle';
import { tabName } from 'components/header/Header.consts';
import { Loader } from 'components/shared/loader';
import { store } from 'app/store';
import { useSelector } from 'react-redux';
import { OrderDirection } from 'utils/types';
import { BaseOffer } from 'utils/types/offers';
import { LoaderSize } from 'components/shared/loader/Loader.consts';
import PageContainer from 'pages/shared/pageContainer/PageContainer';
import { termsConditionsSelection } from 'app/genericSlices/termsConditions';
import { doeOffersPage } from 'app/genericSlices/offers';
import { useQuery } from '@apollo/client';
import { useToastError } from 'hooks/use-toast-error';
import useQueryInterval from 'hooks/use-query-polling';
import { Actions, StyledHeader, StyledLoader, StyledTotalAmountWithGrid } from '../../shared/shared.style';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { FetchPolicies } from 'utils/types/common';
import { OfferFilterBar } from '../offerManagement/components/offerFilterBar/OfferFilterBar';
import { OffersGrid, OffersGridContainer } from '../offerManagement/Offers.style';
import OfferItem from '../offerManagement/components/offerItem/OfferItem';
import { OfferSource } from '../offerManagement/Offers.const';
import doeOffersGqls from './DoeOffers.gqls';

const DoeOffers = () => {
  const { filters } = useSelector(doeOffersPage.offersState);
  const { data, error, fetchMore, startPolling, stopPolling } = useQuery(doeOffersGqls.queries.getOffersScreenData, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: doeOffersPage.getFilters(filters),
        order: { id: OrderDirection.DESC },
        limit: 24,
        offerSource: OfferSource.DOE,
      },
    },
  });
  const {
    getDoeOffers: { items: doeOffers, total },
  } = data || { getDoeOffers: { items: undefined } };
  useToastError(error, 'Error loading doeOffers');
  useQueryInterval(600000, startPolling, stopPolling);

  const fetchNextDoeOffers = async () => {
    if (doeOffers.length < total) {
      await fetchMore({
        variables: {
          data: {
            filters: doeOffersPage.getFilters(filters),
            offset: doeOffers.length,
            limit: 24,
            order: { id: OrderDirection.DESC },
            offerSource: OfferSource.DOE,
          },
        },
      });
    }
  };

  useEffect(() => {
    store.dispatch(termsConditionsSelection.loadTerms());
  }, []);
  return (
    <PageContainer>
      <StyledHeader data-automation-id="header">
        <TextPageTitle text={tabName.DoeOffers} />
        <Actions data-automation-id="actions">
          <OfferFilterBar offerSource={OfferSource.DOE} />
        </Actions>
      </StyledHeader>
      {!doeOffers ? (
        <StyledLoader size={LoaderSize.Large} />
      ) : (
        <>
          <StyledTotalAmountWithGrid amount={total} />
          {Boolean(doeOffers?.length) && (
            <OffersGrid id="offers-scroll">
              <OffersGridContainer
                scrollableTarget="offers-scroll"
                dataLength={doeOffers?.length}
                next={fetchNextDoeOffers}
                hasMore={doeOffers?.length < total}
                loader={<Loader />}
              >
                {doeOffers?.map((offer: BaseOffer) => {
                  return <OfferItem key={offer.id} offer={offer} offerSource={OfferSource.DOE} />;
                })}
              </OffersGridContainer>
              <Tooltip id={'offer-lock-icon'} onHover content="Locked for changes" place="bottom" />
            </OffersGrid>
          )}
        </>
      )}
    </PageContainer>
  );
};

export default DoeOffers;
