import { useLazyQuery } from '@apollo/client';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { baseOfferRecsGqls } from 'pages/campaigns/doeCampaigns/base.offer.recs.gqls';
import { useEffect, useCallback } from 'react';
import { OrderDirection } from 'utils/types';
import { FetchPolicies } from 'utils/types/common';

export const useBaseOfferRecsQuery = (showErrorToast = true, order = { name: OrderDirection.ASC }, filters = {}) => {
  const [load, { data, loading, error, fetchMore }] = useLazyQuery(baseOfferRecsGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: filters ? { offerBankIds: filters } : null,
        order,
        offset: 0,
        limit: 50,
      },
    },
  });

  const items = data?.getBaseOfferRecs?.items || [];
  const total = data?.getBaseOfferRecs?.total || 0;

  useEffect(() => {
    if (error && showErrorToast) {
      showToast(MessageType.Error, 'Error loading Offer Groups');
    }
  }, [error, showErrorToast]);

  // Function to load more items
  const loadMore = () => {
    if (items && items.length < total) {
      fetchMore({
        variables: {
          data: {
            filters: filters ? { offerBankIds: filters } : null,
            order,
            offset: items.length,
            limit: 50,
          },
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult || !fetchMoreResult.getBaseOfferRecs) {
            return previousResult; // If no new data, return the previous result
          }

          // Merge new items with existing items
          return {
            ...previousResult,
            getBaseOfferRecs: {
              ...previousResult.getBaseOfferRecs,
              items: [
                ...previousResult.getBaseOfferRecs.items, // Existing items
                ...fetchMoreResult.getBaseOfferRecs.items, // New fetched items
              ],
              total: fetchMoreResult.getBaseOfferRecs.total,
              __typename: previousResult.getBaseOfferRecs.__typename,
            },
          };
        },
      });
    }
  };
  return { load, items, loadMore };
};
