import { store } from 'app/store';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';
import { DiscountType } from 'utils/types/offers';

export interface TemplateProps {
  onProductSelection?: (key: string, onSave?: (data: any) => void) => any;
  disabled?: boolean;
  mode?: string;
  offerSource?: OfferSource;
}

export const DiscountTypeRecord = (): Record<DiscountType, string> => {
  const { currency } = store.getState()?.config?.config;
  return {
    [DiscountType.MoneyOff]: `${currency} off`,
    [DiscountType.Percent]: '% off',
    [DiscountType.Free]: 'Free',
    [DiscountType.FixedPrice]: 'Fixed Price',
  };
};
