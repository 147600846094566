import React, { useEffect } from 'react';
import TextPageTitle from 'components/shared/text/textPageTitle/TextPageTitle';
import { tabName, tabToPathMap } from 'components/header/Header.consts';
import PageContainer from 'pages/shared/pageContainer/PageContainer';
import { useSelector } from 'react-redux';
import { formatDate } from 'utils/date';
import { ApprovalStatus, ApprovalStatusLabel } from 'utils/types';
import { CampaignAlert, CampaignAlertLabel } from 'utils/types/campaigns';
import Table from 'components/shared/table/Table';
import { getDashboardCampaignsTableProps } from 'pages/dashboard/DashboardCampaignTableProps';
import { CampaignFilterType } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import { OfferFilters } from 'pages/offers/offerManagement/Offers.const';
import { dashboard as dashboardSlice, defaultDashboardData, loadDashboardData } from 'app/slices/dashboard';
import { store } from 'app/store';
import { marketConfig } from 'app/slices/config';
import { useHistory } from 'react-router-dom';
import {
  AlertIcon,
  AlertsHeader,
  Board,
  CampaignSection,
  CampaignsOverview,
  Column,
  InnerBoard,
  LastUpdated,
  NoRedemptionsSection,
  Number,
  OffersOverview,
  OverviewHeader,
  OverviewSubHeader,
  PendingApprovalStartsInBox,
  RedemptionsHeader,
  SideOverviewContent,
  SideOverviewHeader,
  StatusBigBox,
  StatusRow,
  StatusSmallBox,
  StyledHeader,
  WrapperBoard,
} from './Dashboard.style';
import UpdatesOverview from 'pages/dashboard/components/updatesOverview/UpdatesOverview';
import { getEnableManagementByZone } from '../../components/header/components/notificationsMenu/notifications.utils';
import RedemptionOverview from './components/redemptionOverview/RedemptionOverview';

let embz: tabName;

const Dashboard = () => {
  if (getEnableManagementByZone() === false) {
    embz = tabName.Campaigns;
  } else {
    embz = tabName.CampaignManagement;
  }
  const history = useHistory();
  const { config } = useSelector(marketConfig);
  const { dateFormat, timeFormat, startTimezone, approvalRequiredNotificationThreshold } = config;
  const { data } = useSelector(dashboardSlice);
  const campaignRoute = embz;

  const routeToSpecificPath = (tab: tabName, filters = '') => {
    const url = `${tabToPathMap[tab]}?${filters}`;
    history.push(url);
  };

  const openCampaignsById = (ids: number[]) =>
    ids?.length && routeToSpecificPath(campaignRoute, `${CampaignFilterType.Id}=${ids.join(',')}`);

  const openCampaignStatus = (status: ApprovalStatus) =>
    routeToSpecificPath(campaignRoute, `${CampaignFilterType.Status}=${status}`);

  const openCampaignAlerts = (alert: CampaignAlert) =>
    routeToSpecificPath(campaignRoute, `${CampaignFilterType.Alert}=${alert}`);

  const openOfferStatus = (status: ApprovalStatus) =>
    routeToSpecificPath(tabName.Offers, `${OfferFilters.Status}=${status}`);

  const onCampaignNameClick = (id: number) => {
    routeToSpecificPath(campaignRoute, `${CampaignFilterType.SearchQuery}=${id}`);
  };

  useEffect(() => {
    store.dispatch(loadDashboardData());
  }, []);

  return (
    <PageContainer>
      <StyledHeader data-automation-id="header">
        <TextPageTitle text={tabName.Dashboard} />
      </StyledHeader>
      <Board>
        <WrapperBoard>
          <InnerBoard>
            <CampaignsOverview>
              <OverviewHeader>Campaigns Overview</OverviewHeader>
              <CampaignSection data-automation-id="campaigns-overview">
                {data.campaigns.lastUpdated && (
                  <LastUpdated align="right">
                    Last Updated: {formatDate(data.campaigns.lastUpdated, { dateFormat, timeFormat }, startTimezone)}
                  </LastUpdated>
                )}
                <StatusRow>
                  <StatusBigBox
                    color="green"
                    onClick={() => openCampaignStatus(ApprovalStatus.Active)}
                    data-automation-id="campaigns-overview-active"
                  >
                    <Number>{data.campaigns[ApprovalStatus.Active]}</Number>
                    <span>{ApprovalStatusLabel[ApprovalStatus.Active]}</span>
                  </StatusBigBox>
                  <StatusBigBox
                    color="red"
                    onClick={() => openCampaignStatus(ApprovalStatus.Rejected)}
                    data-automation-id="campaigns-overview-rejected"
                  >
                    <Number>{data.campaigns[ApprovalStatus.Rejected]}</Number>
                    <span>{ApprovalStatusLabel[ApprovalStatus.Rejected]}</span>
                  </StatusBigBox>
                  <StatusBigBox
                    color="red"
                    onClick={() => openCampaignStatus(ApprovalStatus.DeploymentFailed)}
                    data-automation-id="campaigns-overview-deployment-failed"
                  >
                    <Number>{data.campaigns[ApprovalStatus.DeploymentFailed]}</Number>
                    <span>{ApprovalStatusLabel[ApprovalStatus.DeploymentFailed]}</span>
                  </StatusBigBox>
                  <StatusBigBox
                    color="yellow"
                    onClick={() => openCampaignStatus(ApprovalStatus.PendingApproval)}
                    data-automation-id="campaigns-overview-pending-approval"
                  >
                    <Number>{data.campaigns[ApprovalStatus.PendingApproval]}</Number>
                    <span>{ApprovalStatusLabel[ApprovalStatus.PendingApproval]}</span>
                    <PendingApprovalStartsInBox
                      onClick={(e) => {
                        e.stopPropagation();
                        openCampaignsById(data.campaigns.pendingApprovalNotice);
                      }}
                    >
                      <Number>{data.campaigns.pendingApprovalNotice.length}</Number>
                      <span>Start in the next {approvalRequiredNotificationThreshold} hours</span>
                    </PendingApprovalStartsInBox>
                  </StatusBigBox>
                </StatusRow>
              </CampaignSection>
              <CampaignSection data-automation-id="alerts">
                <AlertsHeader>
                  <AlertIcon name="error" />
                  <OverviewSubHeader>Alerts</OverviewSubHeader>
                </AlertsHeader>
                <StatusRow>
                  <StatusSmallBox
                    onClick={() => openCampaignAlerts(CampaignAlert.UpdateFailed)}
                    data-automation-id="alerts-update-failed"
                  >
                    <Number isAlert>{data.alerts[CampaignAlert.UpdateFailed]}</Number>
                    <span>{CampaignAlertLabel[CampaignAlert.UpdateFailed]}</span>
                  </StatusSmallBox>
                  <StatusSmallBox
                    onClick={() => openCampaignAlerts(CampaignAlert.RevokeFailed)}
                    data-automation-id="alerts-revoke-failed"
                  >
                    <Number isAlert>{data.alerts[CampaignAlert.RevokeFailed]}</Number>
                    <span>{CampaignAlertLabel[CampaignAlert.RevokeFailed]}</span>
                  </StatusSmallBox>
                  <StatusSmallBox
                    onClick={() => openCampaignAlerts(CampaignAlert.Conflicted)}
                    data-automation-id="alerts-conflicted"
                  >
                    <Number isAlert>{data.alerts[CampaignAlert.Conflicted]}</Number>
                    <span>{CampaignAlertLabel[CampaignAlert.Conflicted]}</span>
                  </StatusSmallBox>
                  <StatusSmallBox
                    onClick={() => openCampaignAlerts(CampaignAlert.StopAssociationFailed)}
                    data-automation-id="alerts-stop-association-failed"
                  >
                    <Number isAlert>{data.alerts[CampaignAlert.StopAssociationFailed]}</Number>
                    <span>{CampaignAlertLabel[CampaignAlert.StopAssociationFailed]}</span>
                  </StatusSmallBox>
                </StatusRow>
              </CampaignSection>
              <NoRedemptionsSection data-automation-id="active-campaigns-without-redemptions">
                <StatusRow>
                  <RedemptionsHeader>
                    Active Campaigns without Redemptions in the past {config.noRedemptionsNotificationThreshold} hours
                  </RedemptionsHeader>
                  {data.campaigns.noRedemptions.lastUpdated && (
                    <LastUpdated>
                      Last Updated:{' '}
                      {formatDate(data.campaigns.noRedemptions.lastUpdated, { dateFormat, timeFormat }, startTimezone)}
                    </LastUpdated>
                  )}
                </StatusRow>
                <StatusSmallBox
                  onClick={() =>
                    openCampaignsById(
                      data.campaigns.noRedemptions.data.campaigns.map((campaign) => campaign.externalId),
                    )
                  }
                  data-automation-id="active-campaigns-without-redemptions-total"
                >
                  <Number isAlert>{data.campaigns.noRedemptions.data.total}</Number>
                  <span>Total</span>
                </StatusSmallBox>
                <Table
                  tableProps={{
                    ...getDashboardCampaignsTableProps(onCampaignNameClick),
                    rows: data.campaigns.noRedemptions.data.campaigns,
                  }}
                  data-automation-id="active-campaigns-without-redemptions-table"
                />
              </NoRedemptionsSection>
            </CampaignsOverview>
            <Column>
              <RedemptionOverview
                redemptions={data.redemptions ?? defaultDashboardData.data.redemptions}
              ></RedemptionOverview>
              <OffersOverview data-automation-id="offers-overview">
                <SideOverviewHeader>Offers Overview</SideOverviewHeader>
                {data.offers.lastUpdated && (
                  <LastUpdated>
                    Last Updated: {formatDate(data.offers.lastUpdated, { dateFormat, timeFormat }, startTimezone)}
                  </LastUpdated>
                )}
                <SideOverviewContent>
                  <StatusSmallBox
                    color="red"
                    onClick={() => openOfferStatus(ApprovalStatus.Rejected)}
                    data-automation-id="offers-overview-rejected"
                  >
                    <Number>{data.offers[ApprovalStatus.Rejected]}</Number>
                    <span>{ApprovalStatusLabel[ApprovalStatus.Rejected]}</span>
                  </StatusSmallBox>
                  <StatusSmallBox
                    color="yellow"
                    onClick={() => openOfferStatus(ApprovalStatus.PendingApproval)}
                    data-automation-id="offers-overview-pending-approval"
                  >
                    <Number>{data.offers[ApprovalStatus.PendingApproval]}</Number>
                    <span>{ApprovalStatusLabel[ApprovalStatus.PendingApproval]}</span>
                  </StatusSmallBox>
                </SideOverviewContent>
              </OffersOverview>
              <UpdatesOverview updates={data.updates} routeToSpecificPath={routeToSpecificPath}></UpdatesOverview>
            </Column>
          </InnerBoard>
        </WrapperBoard>
      </Board>
    </PageContainer>
  );
};

export default Dashboard;
