import { ApprovalStatus } from 'utils/types';
import { ArchiveStatus } from 'pages/settings/settings.const';
import { TableProps } from '../../shared/table/Table.consts';
import { DataGridProProps } from '@mui/x-data-grid-pro';
import { ImpactGenericSlice } from 'app/genericSlices/impacts';
import React from 'react';
import { UsageGenericSlice } from 'app/genericSlices/usage';

export enum ImpactType {
  Campaign = 'Campaign',
  Offer = 'Offer',
  Image = 'Image',
}

export interface Impact {
  id: number | string;
  entityId: number | string;
  title: string;
  status: ApprovalStatus;
  isDefault?: boolean;
  externalId?:number;
  defaultTnC?:string;
}

type AvailableStatus = ApprovalStatus | ArchiveStatus;

export interface ImpactItemProps {
  impactType: ImpactType;
  impactStatuses: AvailableStatus[];
  impacts: Impact[];
  setImpacts?: React.Dispatch<React.SetStateAction<Impact[]>>;
  tableProps: TableProps;
  className?: string;
  totalLength?: number;
  onRowsScrollEnd?: DataGridProProps['onRowsScrollEnd'];
  impactSlice?: ImpactGenericSlice | UsageGenericSlice;
}
