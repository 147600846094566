import { appTheme } from 'styles/themes/defaultTheme';
import { FormMode } from 'utils/types';
import { CampaignProps, FormSection } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';

export const FormTitle: Partial<Record<FormMode, string>> = {
  [FormMode.New]: 'Add Campaign',
  [FormMode.View]: 'View Campaign',
  [FormMode.Edit]: 'Edit Campaign',
  [FormMode.Duplicate]: 'Duplicate Campaign',
};

export const SectionTitle: Record<FormSection, string> = {
  [FormSection.Offer]: 'Offer',
  [FormSection.GeneralDetails]: 'General Details',
  [FormSection.Locations]: 'Locations',
  [FormSection.Schedule]: 'Schedule',
};

export interface CampaignFormProps {
  mode: FormMode;
  campaign?: CampaignProps;
  className?: string;
  theme?: appTheme;
  offerSource?: OfferSource;
  oasAudienceId?: string[];
}

export interface CampaignFormState {
  mode: FormMode;
  campaign?: CampaignProps;
  isFormDirty: boolean;
  isFormValid: {
    [FormSection.Offer]: boolean;
    [FormSection.GeneralDetails]: boolean;
    [FormSection.Locations]: boolean;
    [FormSection.Schedule]: boolean;
  };
}

export type Action =
  | {
      type: 'updateFormMode';
      payload: FormMode;
    }
  | {
      type: 'updateCampaign';
      payload: CampaignProps;
    }
  | {
      type: 'updateIsFormDirty';
      payload: boolean;
    }
  | {
      type: 'updateIsFormValid';
      payload: { formSection: FormSection; isValid: boolean };
    };
