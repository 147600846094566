import styled from '@emotion/styled';
import typography from 'styles/typography';
import { Selectbox } from 'components/shared/selectbox/Selectbox';
import TextField from 'components/shared/textField/TextField';
import { StyledLabelProps } from './DiscountComponent.consts';

export const DiscountComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${typography.unit * 7}px;
`;

export const DiscountComponentRow = styled.div`
  display: flex;
  gap: ${typography.unit * 2}px;
  margin-bottom: ${typography.unit * 2}px;
  &:last-of-type {
    margin-bottom: 0px;
  }
`;
export const StyledSelectbox = styled(Selectbox)`
  display: flex;
  width: 100%;
` as unknown as typeof Selectbox;

export const CurrencyContainer = styled.div`
  display: flex;
  align-self: flex-end;
`;

export const StyledTextField = styled(TextField)`
  input {
    max-width: ${typography.unit * 20}px;
  }
`;

export const StyledLabel = styled.label<StyledLabelProps>`
  display: flex;
  height: ${typography.inputComponentHeight}px;
  justify-content: center;
  align-items: center;
  color: ${({ theme, disabled }) => (disabled ? theme.colors.text.disabled : theme.colors.text.primary)};
  flex: 1;
`;
