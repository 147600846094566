import React, { useEffect, useState } from 'react';
import { DiscountType } from 'utils/types/offers';
import Checkbox from 'components/shared/checkbox/Checkbox';
import { useFormContext, useWatch } from 'react-hook-form';
import { setData } from 'app/slices/modals';
import { store } from 'app/store';
import { calcProductsHint } from 'utils/product';
import useDiscountType from 'hooks/use-discount-type';
import {
  ProductPicker,
  StyledBuySelectbox,
  StyledForSelectbox,
  StyledLabel,
  StyledLightLabel,
  StyledTextField,
} from '../Template.style';
import { DiscountTypeRecord, TemplateProps } from '../Template.consts';
import getProductsAction from '../shared/ProductsActionCondition';
import { Currency } from '../shared/Currency/Currency';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';

export const ComboPriceDeal = ({ disabled, onProductSelection, offerSource, mode }: TemplateProps) => {
  const {
    control,
    register,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext();

  const templateValuesGetProductsPath = 'versions.0.templateValues.getProducts';
  const { discountType, isFree, isPercent, shouldDisplayCurrency } = useDiscountType();
  const buyProducts = useWatch({ control, name: 'versions.0.templateValues.buyProducts' });
  const getProducts = useWatch({ control, name: templateValuesGetProductsPath });
  const [buy, get] = watch(['versions.0.templateValues.buy', 'versions.0.templateValues.upToLimit']);
  const [sameProduct, setSameProduct] = useState(watch('versions.0.templateValues.sameProduct'));
  const doeDisable = (disabled || (offerSource === OfferSource.DOE && mode === 'edit'));

  useEffect(() => {
    setValue('versions.0.templateValues.sameProduct', sameProduct);
    if (sameProduct) {
      setValue(templateValuesGetProductsPath, buyProducts, { shouldValidate: true });
    }
  }, [sameProduct]);

  return (
    <>
      <div>
        <StyledLabel>Buy</StyledLabel>
        <StyledLightLabel>Up to</StyledLightLabel>
        <StyledBuySelectbox
          control={control}
          name="versions.0.templateValues.buy"
          disabled={doeDisable}
          validation={{
            min: 1,
            max: 15,
            required: true,
          }}
          items={Array.from(
            { length: 15 },
            (x, i) =>
              ({
                id: (i + 1).toString(),
                name: (i + 1).toString(),
              } as any),
          )}
          defaultValue="1"
          initialSelectedItems={buy ? [typeof buy === 'object' ? buy.id : buy] : ['1']}
          selectWidth={60}
        />
        <ProductPicker>
          {calcProductsHint(buyProducts)}
          {getProductsAction(
            disabled,
            buyProducts,
            getValues,
            'Select Products',
            'buyProducts',
            (data: any) => ({
              buyProducts: data,
              getProducts: sameProduct ? data : getProducts,
            }),
            onProductSelection,
          )}
          <input
            type="hidden"
            {...register('versions.0.templateValues.buyProducts', {
              value: buyProducts,
              shouldUnregister: true,
              required: true,
            })}
          />
        </ProductPicker>
      </div>
      <div>
        <StyledLabel>Get</StyledLabel>
        <StyledLightLabel>Up to</StyledLightLabel>
        <StyledBuySelectbox
          control={control}
          name="versions.0.templateValues.upToLimit"
          disabled={doeDisable}
          validation={{
            min: 1,
            max: 15,
            required: true,
          }}
          items={Array.from(
            { length: 15 },
            (x, i) =>
              ({
                id: (i + 1).toString(),
                name: (i + 1).toString(),
              } as any),
          )}
          defaultValue="1"
          initialSelectedItems={get ? [typeof get === 'object' ? get.id : get] : ['1']}
          selectWidth={60}
        />
        <ProductPicker>
          {calcProductsHint(getProducts)}
          {getProductsAction(
            disabled || sameProduct,
            getProducts,
            getValues,
            'Select Products',
            'getProducts',
            (data: any) => ({ getProducts: data }),
            onProductSelection,
          )}
          <input
            type="hidden"
            {...register(templateValuesGetProductsPath, {
              value: getProducts,
              shouldUnregister: true,
              required: true,
            })}
          />
        </ProductPicker>
        <StyledLightLabel>or</StyledLightLabel>
        <Checkbox
          checked={sameProduct}
          disabled={disabled}
          onClick={() => {
            setSameProduct(!sameProduct);
            store.dispatch(
              setData({
                data: {
                  sameProduct: !sameProduct,
                  templateProducts: { getProducts: sameProduct ? buyProducts : getProducts },
                },
              }),
            );
          }}
          label="Same product"
        />
      </div>
      <div>
        <StyledLabel>For</StyledLabel>
        <StyledForSelectbox
          name="versions.0.templateValues.discountType"
          control={control}
          disabled={doeDisable}
          validation={{
            required: true,
          }}
          items={
            Object.entries(DiscountTypeRecord()).map(([key, value]) => {
              return {
                id: key,
                name: value,
              };
            }) as any[]
          }
          selectWidth={100}
          defaultValue={DiscountType.MoneyOff}
          initialSelectedItems={discountType ? [discountType] : [DiscountType.MoneyOff]}
        />
        {!isFree && (
          <>
            {shouldDisplayCurrency && <Currency />}
            <StyledTextField
              disabled={doeDisable}
              register={register}
              validation={{
                required: true,
                min: isPercent ? 1 : 0.01,
                max: isPercent ? 99 : null,
                pattern: isPercent ? /^([1-9][0-9]?|99)$/ : /^\d+(?:\.\d{1,2})?$/,
                validate: (v: string) =>
                  (!Number.isNaN(Number(v)) && Number(v) >= 0.01) || (isPercent && Number(v) < 100 && Number(v) >= 1),
              }}
              errors={errors}
              name="versions.0.templateValues.discountValue"
              placeholder="Enter value"
            />
          </>
        )}
      </div>
    </>
  );
};
